import classes from "./BusinessConfigs.module.scss";
import LabelRow from "./LabelRow";
import BizConfigHeader from "./Header";
import { nameof } from "ts-simple-nameof";
import { BusinessInfo } from "../../Types/Types";
import { Select, TextareaAutosize, TextField } from '@material-ui/core';
import { useBizConfigNavBlocking } from "../../hooks/useBizConfigNavBlocking";
import { bizConfigLinks } from "../../components/SideNavigation/SideNavigation";
import {
  AddressLatitudeHelperText,
  AddressLongitudeHelperText,
  DeliveryDistanceHelperText,
  DeliveryDurationHelperText,
  DeliveryUnavailableHelperText,
  ExcludedCitiesHelperText,
  FirstDeliveryKmRangeHelperText,
  MinDeliveryFeeHelperText,
  MinOrderAmountForDeliveryHelperText,
  MinRequiredTimeForDeliveryHelperText,
  PerExtraKmFeeHelperText
} from "../../constants/bizConfigHelperTexts";
import { useMemo, useState } from "react";
import { TagsInput } from "react-tag-input-component";

const timeOptions: number[] = [];
for (let i = 1; i < 10; i += 2) {
  timeOptions.push(i * 30);
  timeOptions.push((i + 1) * 30);
}

const timeOptionSelects = timeOptions.map((val: number) =>
  <option key={val} value={val}>
    {val}
  </option>
)

const DeliveryEdit = () => {


  const {
    formData, setFormData, elements,
    handleChange, saveBusinessInfo
  } = useBizConfigNavBlocking();

  const excludedCities = useMemo(() => {
    if (!formData?.excludedCities) return [];

    const array = formData.excludedCities.split(',');
    const resArr = array.map(x => x.trim()).filter(x => x);

    return resArr;
  }, [formData?.excludedCities]);


  return (
    <div className={classes.orderingEdit}>

      {elements.ConfirmPopup}
      {elements.SnackbarElement}

      <BizConfigHeader title={bizConfigLinks.deliveryEdit.name} onSave={saveBusinessInfo} />

      <LabelRow label="Delivery Unavailable For Address Message"
        helperText={DeliveryUnavailableHelperText}
        content={
          <TextareaAutosize value={formData?.deliveryUnavailableForAddressMessage ?? ""}
            name={nameof<BusinessInfo>(p => p.deliveryUnavailableForAddressMessage)}
            onChange={handleChange}
          />
        }
      />

      <LabelRow label="Address Latitude"
        helperText={AddressLatitudeHelperText}
        content={
          <TextareaAutosize value={formData?.addressLatitude ?? ""}
            name={nameof<BusinessInfo>(p => p.addressLatitude)}
            onChange={handleChange}
          />
        }
      />

      <LabelRow label="Address Longitude"
        helperText={AddressLongitudeHelperText}
        content={
          <TextareaAutosize value={formData?.addressLongitude ?? ""}
            name={nameof<BusinessInfo>(p => p.addressLongitude)}
            onChange={handleChange}
          />
        }
      />

      <LabelRow label="Minimum Order Amount For Free Delivery"
        helperText={MinOrderAmountForDeliveryHelperText}
        content={
          <TextField
            type="number"
            value={formData?.minOrderAmountForFreeDelivery}
            name={nameof<BusinessInfo>(p => p.minOrderAmountForFreeDelivery)}
            onChange={handleChange}
            placeholder="$0.00"
            inputProps={{ min: 0, }}
          />
        }
      />

      <LabelRow label="Minimum Delivery Fee"
        helperText={MinDeliveryFeeHelperText}
        content={
          <TextField
            type="number"
            value={formData?.minimumDeliveryFee}
            name={nameof<BusinessInfo>(p => p.minimumDeliveryFee)}
            onChange={handleChange}
            placeholder="$0.00"
            inputProps={{ min: 0, }}
          />
        }
      />


      <LabelRow label="Per Extra Kilometer Fee"
        helperText={PerExtraKmFeeHelperText}
        content={
          <TextField
            type="number"
            value={formData?.perExtraKilometerFee}
            name={nameof<BusinessInfo>(p => p.perExtraKilometerFee)}
            onChange={handleChange}
            placeholder="$0.00"
            inputProps={{ min: 0, }}
          />
        }
      />

      <LabelRow label="Delivery Distance"
        helperText={DeliveryDistanceHelperText}
        content={
          <TextField
            type="number"
            value={formData?.deliveryDistance}
            name={nameof<BusinessInfo>(p => p.deliveryDistance)}
            onChange={handleChange}
            placeholder="$0.00"
            inputProps={{ min: 0, }}
          />
        }
      />

      <LabelRow label="First Delivery Kilometers Range"
        helperText={FirstDeliveryKmRangeHelperText}
        content={
          <TextField
            type="number"
            value={formData?.firstDeliveryKilometersRange}
            name={nameof<BusinessInfo>(p => p.firstDeliveryKilometersRange)}
            onChange={handleChange}
            placeholder="$0.00"
            inputProps={{ min: 0, }}
          />
        }
      />

      <LabelRow label="Delivery Duration"
        helperText={DeliveryDurationHelperText}
        content={
          <TextField
            type="number"
            value={formData?.deliveryDuration}
            name={nameof<BusinessInfo>(p => p.deliveryDuration)}
            onChange={handleChange}
            placeholder="$0.00"
            inputProps={{ min: 0, }}
          />
        }
      />

      <LabelRow label="Minimum Required Time for Delivery Orders"
        helperText={MinRequiredTimeForDeliveryHelperText}
        content={
          <Select
            native
            value={formData?.deliveryPrepTimeMinimum}
            name={nameof<BusinessInfo>(p => p.deliveryPrepTimeMinimum)}
            onChange={handleChange}
          >
            {timeOptionSelects}
          </Select>
        }
      />

      <LabelRow label="Excluded Cities"
        helperText={ExcludedCitiesHelperText}
        content={
          <TagsInput
            value={excludedCities}
            onChange={(tags: string[]) => {
              const newSelections = tags;
              setFormData(prevState => {
                if (!prevState) return;

                return {
                  ...prevState,
                  excludedCities: newSelections.map(x => x.trim()).join(', ')
                }
              });
            }}
          />
        }
      />
    </div>
  )
}

export default DeliveryEdit;
