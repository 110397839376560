import classes from "./BusinessConfigs.module.scss";
import FramedImage from "../../components/MenuListImage/FramedImage";
import ImgUploadForm from "../../components/ImageUploadForm/ImgUploadForm";
import CheckboxLabels from "../../components/FormControls/CheckboxLabels";
import LabelsAutocomplete from "../../components/LabelsAutocomplete/LabelsAutocomplete";
import LabelRow from "./LabelRow";
import BizConfigHeader from "./Header";
import AddressForm from "./AddressForm";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { nameof } from "ts-simple-nameof";
import { useState, useEffect } from "react";
import { SketchPicker } from 'react-color'
import { uploadBusinessLogo } from "../../api/businessEdit";
import { BizLabel, BusinessInfo } from "../../Types/Types";
import { FormGroup, TextField } from '@material-ui/core';
import { getLabels } from "../../api/bizConfigs";
import { useBizConfigNavBlocking } from "../../hooks/useBizConfigNavBlocking";
import { bizConfigLinks } from "../../components/SideNavigation/SideNavigation";
import {
  addressHelperText, announcementHelperText, bizPhoneHelperText, facebookHelperText, instagramHelperText,
  labelsHelperText, logoHelperText, nameHelperText, textToSpeechHelperText, themeHelperText, websiteHelperText
} from "../../constants/bizConfigHelperTexts";
import { Button } from "semantic-ui-react";
import { NumberMaskInput } from "../../components/FormRow/NumberMaskInput";


const BusinessInfoEdit = () => {
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [isAddressOpen, showAddressOpen] = useState(false);
  const [imgPreview, setImgPreview] = useState("");
  const [imageToUpload, setImgToUpload] = useState<File>();
  const [allLabelsList, setAllLabelsList] = useState<BizLabel[]>([]);

  const {
    formData, setFormData, elements, handleCheckboxChanges,
    handleChange, saveBusinessInfo, fetchBusinessInfo
  } = useBizConfigNavBlocking();

  useEffect(() => {
    const fetchLabels = async () => {
      const labels = await getLabels();
      setAllLabelsList(labels);
    }

    fetchLabels();

    return () => {
      setAllLabelsList([]);
    }
  }, [])

  const uploadImage = async () => {
    if (!imageToUpload) return;
    if (!businessInfo) return;

    const businessId = businessInfo.businessId;
    const result = await uploadBusinessLogo(imageToUpload, businessId);
    if (result && result.status === 200) {
      console.log("Image upload success!", result);
    }
    setOpenUploadModal(false);
    fetchBusinessInfo();
  }


  const addressDisplayVal = (() => {
    if (!formData?.location) return "";
    const locationObj = formData.location;

    let res = locationObj.addressLine1 && `${locationObj.addressLine1} `;
    res += locationObj.addressLine2 && `${locationObj.addressLine2} `;
    res += locationObj.city && `${locationObj.city} `;
    res += locationObj.province && `${locationObj.province} `;
    res += locationObj.postalCode && `${locationObj.postalCode} `;
    return res;
  })();


  return (
    <section className={classes.businessInfoEdit}>

      {elements.SnackbarElement}
      {elements.ConfirmPopup}

      <BizConfigHeader title={bizConfigLinks.businessInfoEdit.name} onSave={saveBusinessInfo} />

      <div className={classes.content}>


        <ImgUploadForm
          isOpen={openUploadModal}
          onValidImage={(file) => {
            setImgPreview(URL.createObjectURL(file));
            setImgToUpload(file);
          }}
          currentImage={imgPreview}
          onOk={uploadImage}
          onCancel={e => {
            setImgPreview("");
            setOpenUploadModal(false);
            setImgToUpload(undefined);
          }}
          isImgAdd={false}
        />

        <LabelRow label="Name"
          content={
            <TextField type="text"
              name={nameof<BusinessInfo>(p => p.name)}
              inputProps={{
                maxLength: 60,
              }}
              value={formData?.name ?? ""}
              helperText={nameHelperText}
              onChange={handleChange}
            />
          } />

        <LabelRow label="Text to Speech Business Name"
          content={
            <TextField type="text"
              name={nameof<BusinessInfo>(p => p.textToVoiceBusinessName)}
              inputProps={{
                maxLength: 100,
              }}
              value={formData?.textToVoiceBusinessName ?? ""}
              helperText={textToSpeechHelperText}
              onChange={handleChange}
            />
          }
        />

        <AddressForm
          isOpenState={[isAddressOpen, showAddressOpen]}
          saveBusinessInfo={saveBusinessInfo}
          formData={formData}
        />

        <LabelRow label="Address"
          content={
            <>
              <TextField type="text"
                name={nameof<BusinessInfo>(p => p.location)}
                value={addressDisplayVal}
                helperText={addressHelperText}
              />
              <Button
                content="Edit"
                onClick={() => showAddressOpen(true)}
              />
            </>
          }
        />

        <LabelRow label="Phone Number"
          content={
            <TextField type="Phone Number"
              name={nameof<BusinessInfo>(p => p.serviceProviderUserPhone)}
              inputProps={{
                maxLength: 100,
              }}
              InputProps={{
                inputComponent: NumberMaskInput,
                inputProps: {
                  mask: '(000) 000 0000',
                  placeholder: '#',
                }
              }}
              value={formData?.serviceProviderUserPhone ?? ""}
              helperText={bizPhoneHelperText}
              onChange={handleChange}
            />
          }
        />

        <LabelRow label="Logo"
          helperText={logoHelperText}
          content={
            <FramedImage
              image={formData?.logo}
              isCard={false}
              onImgEditClicked={e => setOpenUploadModal(true)} />
          } />

        <LabelRow label="Theme"
          helperText={themeHelperText}
          content={<>

            <div className={classes.body_row_swatch}
              onClick={e => setDisplayColorPicker(true)}
            >
              <div className={classes.color}
                style={{
                  backgroundColor: formData?.theme?.color
                }} />
            </div>

            {displayColorPicker &&
              <div className={classes.body_row_popover}>
                <div className={classes.body_row_cover}
                  onClick={e => setDisplayColorPicker(false)}
                />
                <SketchPicker color={formData?.theme?.color ?? ""}
                  onChange={color => {
                    if (!formData) return;
                    const newData = { ...formData };
                    if (!newData.theme) return;
                    newData.theme.color = color.hex;
                    setFormData(newData);
                  }}
                />
              </div>
            }
          </>}
        />

        <LabelRow
          helperText={announcementHelperText}
          label={
            <>
              <span>Announcement</span>
              <FormGroup row className={classes.inner_row}>
                <CheckboxLabels
                  label="Show"
                  name={nameof<BusinessInfo>(p => p.announcement.show)}
                  checkOnChange={e => handleCheckboxChanges(e, "yes", "no")}
                  value={formData?.announcement.show === "yes" ? true : false}
                />
              </FormGroup>
            </>}
          content={
            <textarea value={formData?.announcement.message ?? ""}
              name={nameof<BusinessInfo>(p => p.announcement.message)}
              onChange={handleChange}
            />
          }
        />


        <LabelRow label="Labels"
          helperText={labelsHelperText}
          content={
            <LabelsAutocomplete
              className={classes.labelsAutocomplete}
              placeholder="#vegan; #greek; #seafood..."
              value={formData?.businessLabels ?? []}
              options={allLabelsList}
              nameSelector={option => `#${option.businessLabel}`}
              filterOptions={(options, state) => {
                if (!formData?.businessLabels) {
                  return options;
                }

                const ids = formData?.businessLabels
                  .map(lbl => lbl.businessLabelId);

                return options.filter(lbl => {
                  if (ids.includes(lbl.businessLabelId)) return false;
                  return lbl.businessLabel.includes(state.inputValue);
                })
              }}
              onChange={(e, currSelections) => {
                const newSelections = currSelections as BizLabel[];
                setFormData(prevState => {
                  if (!prevState) return;

                  return {
                    ...prevState,
                    businessLabels: newSelections
                  }
                });
              }}
            />
          }
        />



        <LabelRow label="Website"
          content={<TextField
            className={classes.urlInput}
            name={nameof<BusinessInfo>(p => p.websiteUri)}
            type="text"
            value={formData?.websiteUri ?? ""}
            helperText={websiteHelperText}
            onChange={handleChange}
          />}
        />

        <LabelRow label="Instagram"
          content={<TextField
            className={classes.urlInput}
            name={nameof<BusinessInfo>(p => p.instagramUri)}
            type="text"
            value={formData?.instagramUri ?? ""}
            helperText={instagramHelperText}
            onChange={handleChange}
          />}
        />

        <LabelRow label="Facebook"
          content={<TextField
            className={classes.urlInput}
            name={nameof<BusinessInfo>(p => p.facebookUri)}
            type="text"
            value={formData?.facebookUri ?? ""}
            helperText={facebookHelperText}
            onChange={handleChange}
          />}
        />

        <CheckboxLabels
          label="Menu Item Category Collapsible"
          name={nameof<BusinessInfo>(p => p.menuItemCollapsible)}
          checkOnChange={e => handleCheckboxChanges(e)}
          value={formData?.menuItemCollapsible}
        />

      </div>
    </section >
  );
};



export default BusinessInfoEdit;