import classes from './OptionForms.module.scss';
import GlobalModal from '../GlobalModal/GlobalModal';
import FormRow, { ChangeEventParams } from '../FormRow/FormRow';
import FormVisibilityRules from '../FormVisibilityRules/FormVisibilityRules';
import FormLocations from '../FormLocations/FormLocations';
import { nameof } from 'ts-simple-nameof';
import { useState, ChangeEvent, useEffect } from "react";
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Button } from '@material-ui/core';
import { MenuProductDTO, NameBoolean, NameString, OptionFormData, ProductOptionDTO, VisibilityRulesDTO } from '../../Types/Types';
import { daysFields, formatMoney, normalizeMoney, nullReplacer, numberToString, timeStrToNumber } from '../../common/pageUtils';
import { addOption, deleteOption, getOption, updateOption } from '../../api/optionsList';
import { AddCondition, forceCustomizationBeforeAddOptions, optionAddConditionOptions, OptionType, optionTypeOptions, soldOutRadioOptions } from '../../constants/formConstants';

interface OptionFormProps {
  showModal: boolean;
  setModalOpen: Function;
  optionId?: number;
  productId: number;
  latestSortingOrder: number;
  categoryProducts: MenuProductDTO[];
}

const OptionForm: React.FC<OptionFormProps> = (props) => {
  const { showModal, setModalOpen, optionId, productId,
    latestSortingOrder, categoryProducts } = props;
  const { setAppLoading, setAppLoaded } = useActions();
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [isEdit] = useState(optionId ? true : false);
  const [nameValidationError, setNameValidationError] = useState("");
  const [formData, setFormData] = useState<OptionFormData>({
    price: 0,
    tax: 0,
    selected: undefined,
    optionImage: undefined,
    productId: productId,
    label: "",
    soldout: false,
    forceCustomizationBeforeAdd: false,
    sortingOrder: latestSortingOrder,
    optionDescription: "",
    type: OptionType.all,
    addCondition: AddCondition.all,
    visibilityRules: {
      startTime: "",
      endTime: "",
      startDate: "",
      endDate: "",
      Sun: false, Mon: false,
      Tue: false, Wed: false,
      Thu: false, Fri: false, Sat: false,
      type: ""
    },
    locationAvailabilities: []
  });

  const [originalData, setOriginalData] = useState<OptionFormData>({
    ...formData,
    visibilityRules: { ...formData.visibilityRules }
  });

  const fetchOptionAsync = async () => {
    if (!optionId) return;
    const res = await getOption(optionId);

    const option = res.data;
    const visibilityRule = option.visibilityRuleJson as VisibilityRulesDTO;

    const newVisibilityRule = {
      ...formData.visibilityRules,
      ...option.visibilityRuleJson,
      endTime: numberToString(visibilityRule?.endTime),
      startTime: numberToString(visibilityRule?.startTime),
    }

    if (visibilityRule && visibilityRule.days) {
      daysFields.forEach(day =>
        newVisibilityRule[day] = visibilityRule.days.includes(day)
      )
    }
    delete option.visibilityRuleJson;
    delete newVisibilityRule.days;

    const mappedData: OptionFormData = {
      ...option,
      price: formatMoney(option.price),
      tax: option.tax ?? formData.tax,
      visibilityRules: newVisibilityRule,
      locationAvailabilities: option.locationAvailabilities,
      type: option.type ?? OptionType.all,
      addCondition: option.addCondition ?? AddCondition.all,
    };


    const originalData = {
      ...mappedData,
      visibilityRules: { ...mappedData.visibilityRules },
      locationAvailabilities: [...mappedData.locationAvailabilities],
    };

    setFormData(mappedData);
    setOriginalData(originalData);
  }

  const fetchData = async () => {
    setAppLoading();
    await fetchOptionAsync();
    setAppLoaded();
  }

  useEffect(() => {
    fetchData();
  }, [optionId, businessInfo?.serviceProviderUserId]);

  const handleChange = (event: ChangeEventParams) => {
    const target = event.target;
    let nameProp = target.name;
    const checkedInputs = ["checkbox", "radio"]
    let value = checkedInputs.includes(target.type) ? target.checked : target.value;

    if (formData.hasOwnProperty(nameProp)) {
      setFormData({ ...formData, [nameProp]: value });
      return;
    }

    if (formData.visibilityRules.hasOwnProperty(nameProp)) {
      const newVisibility = formData.visibilityRules;
      newVisibility[nameProp] = value;
      setFormData({ ...formData, visibilityRules: newVisibility });
      return;
    }

    const nameSplit = nameProp.split('_');
    const index = +(nameSplit.at(1) ?? '');
    nameProp = nameSplit.at(0) ?? '';
    const newLocations = formData.locationAvailabilities ?? [];
    const locationAvailability = newLocations.at(index);
    if (locationAvailability?.hasOwnProperty(nameProp)) {
      locationAvailability[nameProp] = value;
      setFormData({ ...formData, locationAvailabilities: newLocations });
      return;
    }

    console.warn(`No prop on formData or visibilityRules called ${nameProp}!`);
  }

  const modalBody = (
    <div className={classes.form}>
      <FormRow<MenuProductDTO>
        label="Menu Item Category"
        name={nameof<OptionFormData>(p => p.productId)}
        inputType="dropdown"
        options={categoryProducts}
        value={formData.productId}
        displayField={val => val.name}
        valueField={val => val.id}
        handleChange={handleChange}
      />
      <FormRow<NameString>
        label="Type"
        name={nameof<OptionFormData>(p => p.type)}
        inputType="dropdown"
        options={optionTypeOptions}
        value={formData.type}
        displayField={val => val.name}
        valueField={val => val.value}
        handleChange={handleChange}
      />
      <FormRow<NameString>
        label="Add Condition"
        name={nameof<OptionFormData>(p => p.addCondition)}
        inputType="dropdown"
        options={optionAddConditionOptions}
        value={formData.addCondition}
        displayField={val => val.name}
        valueField={val => val.value}
        handleChange={handleChange}
      />
      <FormRow<NameBoolean>
        name={nameof<OptionFormData>(p => p.soldout)}
        inputType="radio"
        value={formData.soldout}
        handleChange={handleChange}
        options={soldOutRadioOptions}
        valueField={radioOption => radioOption.value}
        displayField={radioOption => radioOption.name}
      />
      <FormRow<NameBoolean>
        name={nameof<OptionFormData>(p => p.forceCustomizationBeforeAdd)}
        inputType="radio"
        value={formData.forceCustomizationBeforeAdd}
        handleChange={handleChange}
        options={forceCustomizationBeforeAddOptions}
        valueField={radioOption => radioOption.value}
        displayField={radioOption => radioOption.name}
      />
      <FormRow
        label="Label"
        name={nameof<OptionFormData>(p => p.label)}
        inputType="text"
        value={formData.label}
        placeholder="Rice"
        handleChange={handleChange}
      />
      <div>{nameValidationError}</div>
      <FormRow
        label="Description"
        name={nameof<OptionFormData>(p => p.optionDescription)}
        inputType="textarea"
        value={formData.optionDescription}
        placeholder="Description"
        handleChange={handleChange}
      />
      <FormRow
        label="Price"
        name={nameof<OptionFormData>(p => p.price)}
        inputType="price"
        value={formData.price}
        placeholder="1.00"
        handleChange={handleChange}
      />
      <FormRow
        label="Tax"
        name={nameof<OptionFormData>(p => p.tax)}
        inputType="number"
        percent
        value={formData.tax}
        placeholder="1.00"
        handleChange={handleChange}
      />
      <FormVisibilityRules
        visibilityRules={formData.visibilityRules}
        handleChange={handleChange} />

      <FormLocations
        list={formData.locationAvailabilities}
        handleChange={handleChange} />

    </div>);

  const getFormDtoSnapshot = () => {
    const newData = { ...formData } as any;
    delete newData.visibilityRules;
    const days = daysFields.filter(day => formData.visibilityRules[day] === true);
    const formDTO: ProductOptionDTO = {
      ...newData,
      price: normalizeMoney(formData.price),
      visibilityRuleJson: {
        startTime: timeStrToNumber(formData.visibilityRules.startTime),
        endTime: timeStrToNumber(formData.visibilityRules.endTime),
        startDate: formData.visibilityRules.startDate,
        endDate: formData.visibilityRules.endDate,
        type: formData.visibilityRules.type,
        days,
      },
    }

    if (originalData.productId !== formData.productId) {
      formDTO.sortingOrder = Math.floor(Math.random() * 10000) + 1000;
    }
    return formDTO;
  }

  const onModalClose = (e) => {
    const originalJsonStr = JSON.stringify(originalData, nullReplacer);
    const dirtiedJsonStr = JSON.stringify(formData, nullReplacer);
    if (originalJsonStr !== dirtiedJsonStr) {
      setOpenConfirmPopup(true);
      return;
    }
    setModalOpen(false);
  }

  const onAdd = async (e) => {
    if (optionId) return;
    if (!validateForm()) return;

    try {
      setAppLoading();
      const newProduct = getFormDtoSnapshot();
      const res = await addOption(newProduct);
      if (res.status === 200 && !isNaN(Number(res.data))) {
        setModalOpen(false);
        setOpenDeleteConfirm(false);
      }
    } catch (err) {
      console.error("Add Menu Item failed: ", err);
    } finally {
      setAppLoaded();
    }
  }

  const onSave = async (e) => {
    if (!optionId) return;
    if (!validateForm()) return;

    const updatedOption = getFormDtoSnapshot();
    console.log(updatedOption);

    try {
      setAppLoading();
      const res = await updateOption(updatedOption);
      if (res.data === "success") {
        setModalOpen(false);
      }
    } catch (err) {
      console.error("Something went wrong with updating the option", err);
    } finally {
      setAppLoaded();
    }
  }

  const onPopupOk = async (event) => {
    if (openConfirmPopup) {
      setOpenConfirmPopup(false);
      setModalOpen(false);
      return;
    }

    if (!openDeleteConfirm) return;
    if (!optionId) return;
    if (!businessInfo) return;

    try {
      setAppLoading();
      const res = await deleteOption(optionId, businessInfo.serviceProviderUserId);
      if (res.data === "error") {
        alert('Cannot delete options, it probably has customizations.');
      }
    } catch (err) {
      console.error("Delete option failed: ", err);
    } finally {
      setOpenDeleteConfirm(false);
      setModalOpen(false);
      setAppLoaded();
    }
  }

  const onPopupClose = (event) => {
    setModalOpen(true);
    setOpenConfirmPopup(false);
    setOpenDeleteConfirm(false);
  }

  const validateForm = () => {
    // if (formData.label.length === 0) {
    //   setNameValidationError("Option name is required!");
    //   return false;
    // }

    setNameValidationError("");
    return true;
  }

  const getTitle = () => {
    const formTitle = `${isEdit ? "Edit" : "Add"} Menu Item`;
    return openConfirmPopup || openDeleteConfirm ? "Warning!" : formTitle;
  }

  const closeOnDirtyDataConfirmBody = (
    <div>
      <div>There are unsaved modifications</div>
      <div>Are you sure you want to leave without saving?</div>
    </div>
  );

  const deleteConfirmBody = (
    <div>Are you sure you want to delete this option?</div>
  );

  const getBody = () => {
    const confirmBody = openDeleteConfirm ? deleteConfirmBody : closeOnDirtyDataConfirmBody;
    return openConfirmPopup || openDeleteConfirm ? confirmBody : modalBody;
  }

  const modalButtons = (
    <div className={classes.form_footer_buttons}>
      <Button onClick={onModalClose}
        className={classes.form_footer_buttons_cancel}
      >Cancel</Button>
      {isEdit ? <>
        <Button onClick={onSave}
          className={classes.form_footer_buttons_save}
          style={{ backgroundColor: businessInfo?.theme?.color }}
        >Save</Button>
        <Button onClick={e => setOpenDeleteConfirm(true)}
          className={classes.form_footer_buttons_delete}
        >Delete</Button>
      </>
        : <Button onClick={onAdd}
          className={classes.form_footer_buttons_add}
          style={{ backgroundColor: businessInfo?.theme?.color }}
        >Add</Button>}
    </div>
  );

  const confirmButtons = (
    <div className={classes.form_footer_buttons}>
      <Button onClick={onPopupClose}
        className={classes.form_footer_buttons_cancel}
      >No</Button>
      <Button onClick={onPopupOk}
        className={classes.form_footer_buttons_save}
        style={{ backgroundColor: businessInfo?.theme?.color }}
      >Yes</Button>
    </div>
  )

  const getButtons = () => {
    return openConfirmPopup || openDeleteConfirm ? confirmButtons : modalButtons;
  }

  return (
    <GlobalModal
      open={showModal}
      handleClose={onModalClose}
      title={getTitle()}
      body={getBody()}
      buttons={getButtons()}
    />
  );
};

export default OptionForm;
