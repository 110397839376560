
export const nameHelperText = "Business Location's name";
export const textToSpeechHelperText = "Write the name so that the text to robot voice is accurate";
export const addressHelperText = "Business Location's address";
export const bizPhoneHelperText = "This is a phone number customers can use to contact you"
export const logoHelperText = "Upload only if it's different from the parent business's logo";
export const themeHelperText = "Pick a relatively dark colour for better visibility";
export const announcementHelperText = "This will be displayed only once in 24 hours";
export const labelsHelperText = "Add hashtags to help customers find your business easier";
export const websiteHelperText = "Add if it's different from the parent business's website";
export const instagramHelperText = "Add if it's different from the parent business's Instagram";
export const facebookHelperText = "Add if it's different from the parent business's Facebook";
export const specialRequestHintHelperText = "This provides examples of the accepted special instructions";
export const diningOptionsHelperText = "Customers can pre-order only within the next 10 days of your open hours";
export const tipOptionsHelperText = "Note the first option is the default amount that customers views";
export const verifyPhoneNumberHelperText = "This will ask customers to confirm their phone number before submitting the order";
export const orderPickupInstructionsHelperText = "This appears in the order status tracking when the order is ready ";
export const disableOrderingHelperText = "This will activate the menu-only option";
export const customerPhoneNumberRequiredHelperText = "The phone number will be required and only used for notifying them when the order is ready";
export const customerNameRequiredHelperText = "If checked, the customer’s name will be required to order.";
export const orderEmailNotificationHelperText = "The business will receive an email for each order ";
export const mobilePaymentDisabledHelperText = "Customers will not be able to use their credit card or mobile pay";
export const payInPersonDisabledHelperText = "Customers will have to pay in advance";
export const paperBagFeeHelperText = "e.g. $0.15 ";
export const orderReminderPhoneNumberHelperText = "If you provide a number, you will receive a phone call when an order is not confirmed on time ";
export const orderReminderPhoneNumberWaitTimeMinutesHelperText = "This is the minimum time to wait for confirmation";
export const preOrderLimitWarningHelperText = "This is the Warning Message for invalid date selection for pre-order.";
export const preOrderPrepMaxDaysHelperText = "This is the Maximum days required to prepare an order.";
export const preOrderPrepMinDaysHelperText = "This is the Minimum days required to prepare an order.";

export const diningConfigDescHelperText = "This appears in the order type selection before checkout";
export const diningConfigPrepTimeHelperText = "This appears in the order type selection before checkout";



// DELIVERY
export const DeliveryUnavailableHelperText = "User views this message when delivery not available for an address";
export const AddressLatitudeHelperText = "Your store's Google Map Latitude";
export const AddressLongitudeHelperText = "Your store's Google Map Longitude";

export const MinOrderAmountForDeliveryHelperText = "e.g. $100";
export const MinDeliveryFeeHelperText = "e.g. $10";
export const PerExtraKmFeeHelperText = "e.g. $2";

export const DeliveryDistanceHelperText = "e.g. 5 Kilometers";
export const FirstDeliveryKmRangeHelperText = "e.g. first 3 Kilometers distance is included in the minimum delivery fee.";
export const DeliveryDurationHelperText = "e.g. within 15 minutes drive to the address";
export const MinRequiredTimeForDeliveryHelperText = "e.g. at least 50 minutes is required to prepare and deliver the orders";
export const ExcludedCitiesHelperText = "Add the cities where delivery is not available";
// DELIVERY

