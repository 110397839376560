import classes from "./BusinessConfigs.module.scss";
import CheckboxLabels from "../../components/FormControls/CheckboxLabels";
import LabelRow from "./LabelRow";
import BizConfigHeader from "./Header";
import { nameof } from "ts-simple-nameof";
import { BusinessInfo } from "../../Types/Types";
import { FormHelperText, MenuItem, Select, TextareaAutosize, TextField } from '@material-ui/core';
import { useBizConfigNavBlocking } from "../../hooks/useBizConfigNavBlocking";
import { bizConfigLinks } from "../../components/SideNavigation/SideNavigation";
import { formatMoney } from "../../common/pageUtils";
import { getFormattedPhoneNumber, normalizeFormattedPhoneNumber } from "../../common/phoneUtils";
import {
  customerPhoneNumberRequiredHelperText, diningConfigDescHelperText, diningConfigPrepTimeHelperText, diningOptionsHelperText, disableOrderingHelperText,
  mobilePaymentDisabledHelperText, orderEmailNotificationHelperText, orderPickupInstructionsHelperText,
  orderReminderPhoneNumberHelperText, orderReminderPhoneNumberWaitTimeMinutesHelperText, paperBagFeeHelperText,
  payInPersonDisabledHelperText, preOrderLimitWarningHelperText, preOrderPrepMaxDaysHelperText, preOrderPrepMinDaysHelperText, specialRequestHintHelperText, tipOptionsHelperText, verifyPhoneNumberHelperText
} from "../../constants/bizConfigHelperTexts";
import { minPaperBagFee } from "../../appConfigs";
import { cloneBusinessInfo } from "../../common/bizConfigUtils";
import { NumberMaskInput } from "../../components/FormRow/NumberMaskInput";


const verifyPhoneOptions = [
  { name: "Never", value: "never" },
  { name: "Always", value: "always" },
  { name: "Pay In Person", value: "payInPerson" },
]


const OrderingEdit = () => {

  const onFetched = (bizInfo: BusinessInfo) => {
    const formattedPaperBagFee = formatMoney(bizInfo.paperBagFee);
    if (bizInfo.paperBagFee) {
      bizInfo.paperBagFee = formattedPaperBagFee;
    }
  }

  const {
    formData, setFormData, elements,
    validationErrors, clearError,
    handleCheckboxChanges, handleChange, saveBusinessInfo
  } = useBizConfigNavBlocking(onFetched);

  const handleTipChange = (event, index) => {
    if (!formData?.tip) return;
    const newData = { ...formData };
    newData.tip[index] = Number(event.currentTarget.value);
    setFormData(newData)
  }

  const onSaveBizInfo = () => {
    if (!formData) return;

    const dataToSave = cloneBusinessInfo(formData);
    if (dataToSave.paperBagFee) {
      dataToSave.paperBagFee = +dataToSave.paperBagFee * 100;
    }

    saveBusinessInfo(dataToSave);
  }

  return (
    <div className={classes.orderingEdit}>

      {elements.ConfirmPopup}
      {elements.SnackbarElement}

      <BizConfigHeader title={bizConfigLinks.orderingEdit.name} onSave={onSaveBizInfo} />

      <LabelRow label="Special Request Hint"
        helperText={specialRequestHintHelperText}
        content={
          <TextareaAutosize value={formData?.specialRequestHint ?? ""}
            name={nameof<BusinessInfo>(p => p.specialRequestHint)}
            onChange={handleChange}
          />
        }
      />

      <LabelRow label="Dining Options"
        helperText={diningOptionsHelperText}
        content={
          <>
            <CheckboxLabels
              label="Take-Out"
              name={nameof<BusinessInfo>(p => p.diningOption.takeout)}
              checkOnChange={e => handleCheckboxChanges(e, "yes", "no")}
              value={formData?.diningOption.takeout === "yes" ? true : false}
            />
            <CheckboxLabels
              label="Dine-In"
              name={nameof<BusinessInfo>(p => p.diningOption.dinein)}
              checkOnChange={e => handleCheckboxChanges(e, "yes", "no")}
              value={formData?.diningOption.dinein === "yes" ? true : false}
            />
            <CheckboxLabels
              label="Pre-Order"
              name={nameof<BusinessInfo>(p => p.diningOption.preorder)}
              checkOnChange={e => handleCheckboxChanges(e, "yes", "no")}
              value={formData?.diningOption.preorder === "yes" ? true : false}
            />
            <CheckboxLabels
              label="Delivery"
              name={nameof<BusinessInfo>(p => p.diningOption.delivery)}
              checkOnChange={e => handleCheckboxChanges(e, "yes", "no")}
              value={formData?.diningOption.delivery === "yes" ? true : false}
            />
          </>
        }
      />

      <LabelRow label="Take-Out Description"
        content={
          <TextField
            name={nameof<BusinessInfo>(p => p.diningOption.takeoutDesc)}
            value={formData?.diningOption?.takeoutDesc}
            helperText={diningConfigDescHelperText}
            onChange={handleChange}
          />
        }
      />

      <LabelRow label="Minimum Prep Time for Take-Out"
        content={
          <TextField type="number"
            name={nameof<BusinessInfo>(p => p.diningOption.takeoutPrepTimeMinimum)}
            value={formData?.diningOption?.takeoutPrepTimeMinimum ?? 0}
            inputProps={{ min: 0, }}
            helperText={diningConfigPrepTimeHelperText}
            onChange={handleChange}
          />
        }
      />

      <LabelRow label="Dine-In Description"
        content={
          <TextField
            name={nameof<BusinessInfo>(p => p.diningOption.dineinDesc)}
            value={formData?.diningOption?.dineinDesc}
            helperText={diningConfigDescHelperText}
            onChange={handleChange}
          />
        }
      />

      <LabelRow label="Minimum Prep Time for Dine-In"
        content={
          <TextField type="number"
            name={nameof<BusinessInfo>(p => p.diningOption.dineinPrepTimeMinimum)}
            value={formData?.diningOption?.dineinPrepTimeMinimum ?? 0}
            inputProps={{ min: 0, }}
            helperText={diningConfigPrepTimeHelperText}
            onChange={handleChange}
          />
        }
      />

      <LabelRow label="Pre-Order Description"
        content={
          <TextField
            name={nameof<BusinessInfo>(p => p.diningOption.preorderDesc)}
            value={formData?.diningOption?.preorderDesc}
            helperText={diningConfigDescHelperText}
            onChange={handleChange}
          />
        }
      />

      <LabelRow label="Minimum Prep Time for Pre-Order"
        content={
          <TextField type="number"
            name={nameof<BusinessInfo>(p => p.diningOption.preorderPrepTimeMinimum)}
            value={formData?.diningOption?.preorderPrepTimeMinimum ?? 0}
            inputProps={{ min: 0, }}
            helperText={diningConfigPrepTimeHelperText}
            onChange={handleChange}
          />
        }
      />

      <LabelRow label="Delivery Description"
        content={
          <TextField
            name={nameof<BusinessInfo>(p => p.diningOption.deliveryDesc)}
            value={formData?.diningOption?.deliveryDesc}
            helperText={diningConfigDescHelperText}
            onChange={handleChange}
          />
        }
      />

      <LabelRow label="Minimum Prep Time for Delivery"
        content={
          <TextField type="number"
            name={nameof<BusinessInfo>(p => p.diningOption.deliveryPrepTimeMinimum)}
            value={formData?.diningOption?.deliveryPrepTimeMinimum ?? 0}
            inputProps={{ min: 0, }}
            helperText={diningConfigPrepTimeHelperText}
            onChange={handleChange}
          />
        }
      />

      <LabelRow label="Tip Options (%)"
        helperText={tipOptionsHelperText}
        contentClassName={classes.tipsInputs}
        multiInput
        content={
          formData?.tip.map((tip, index) => {
            return (
              <TextField
                type="number"
                value={tip === 0 ? undefined : tip}
                onChange={event => handleTipChange(event, index)}
                placeholder="0"
              />
            )
          })
        }
      />


      <LabelRow label="Verify Phone Number"
        helperText={verifyPhoneNumberHelperText}
        content={
          <Select value={formData?.verifyPhone === undefined ? "" : formData?.verifyPhone}
            name={nameof<BusinessInfo>(p => p.verifyPhone)}
            onChange={handleChange as any}>
            {verifyPhoneOptions.map(item => {
              return (
                <MenuItem key={item.value} value={item.value}>
                  {item.name ? item.name : "None"}
                </MenuItem>
              )
            })}
          </Select>
        }
      />


      <LabelRow label="Order Pickup Instructions"
        helperText={orderPickupInstructionsHelperText}
        content={
          <textarea value={formData?.pickupInstruction ?? ""}
            name={nameof<BusinessInfo>(p => p.pickupInstruction)}
            onChange={handleChange}
          />
        }
      />

      <LabelRow multiInput
        content={
          <>
            <div>
              <CheckboxLabels
                label="Disable Ordering"
                name={nameof<BusinessInfo>(p => p.menuOnly)}
                checkOnChange={handleChange}
                value={formData?.menuOnly ?? false}
              />

              <FormHelperText className={classes.helperText}>
                {disableOrderingHelperText}
              </FormHelperText>
            </div>

            <div>
              <CheckboxLabels
                label="Pay in Person Disabled"
                name={nameof<BusinessInfo>(p => p.payInPersonDisabled)}
                checkOnChange={handleChange}
                value={formData?.payInPersonDisabled ?? false}
              />

              <FormHelperText className={classes.helperText}>
                {payInPersonDisabledHelperText}
              </FormHelperText>
            </div>


          </>
        }
      />

      <LabelRow multiInput
        content={
          <>
            <div>
              <CheckboxLabels
                label="Order Email Notification"
                name={nameof<BusinessInfo>(p => p.emailOrderToRestaurant)}
                checkOnChange={handleChange}
                value={formData?.emailOrderToRestaurant ?? false}
              />

              <FormHelperText className={classes.helperText}>
                {orderEmailNotificationHelperText}
              </FormHelperText>
            </div>

            <div>
              <CheckboxLabels
                label="Mobile Payment Disabled"
                name={nameof<BusinessInfo>(p => p.mobilePaymentDisabled)}
                checkOnChange={handleChange}
                value={formData?.mobilePaymentDisabled ?? false}
              />

              <FormHelperText className={classes.helperText}>
                {mobilePaymentDisabledHelperText}
              </FormHelperText>
            </div>

          </>
        }
      />

      <LabelRow multiInput
        content={
          <>

            <div>
              <CheckboxLabels
                label="Customer Phone Number required"
                name={nameof<BusinessInfo>(p => p.customerContactInfo)}
                value={formData?.customerContactInfo === "optional" ? false : true}
                checkOnChange={e => handleCheckboxChanges(e, "required", "optional")}
              />

              <FormHelperText className={classes.helperText}>
                {customerPhoneNumberRequiredHelperText}
              </FormHelperText>
            </div>

            <div>
              <CheckboxLabels
                label="Customer Name required"
                name={nameof<BusinessInfo>(p => p.customerNameRequired)}
                value={formData?.customerNameRequired ? true : false}
                checkOnChange={e => handleCheckboxChanges(e)}
              />

              <FormHelperText className={classes.helperText}>
                {customerPhoneNumberRequiredHelperText}
              </FormHelperText>
            </div>

          </>
        }
      />



      <LabelRow label="Paper Bag Fee"
        content={
          <TextField type="text"
            name={nameof<BusinessInfo>(p => p.paperBagFee)}
            placeholder="$0.00"
            value={(() => {
              if (formData?.paperBagFee === undefined) return;
              if (formData?.paperBagFee === null) return;
              if (formData?.paperBagFee === 0) return;

              return "$" + formData?.paperBagFee;
            })()}
            helperText={paperBagFeeHelperText}
            onChange={e => {
              if (!formData) return;
              let value = e.target.value;
              const indexOfDollar = value.indexOf("$");
              if (indexOfDollar !== -1) {
                value = value.substring(indexOfDollar + 1);
              }

              const numberVal = +value;
              const isNan = isNaN(numberVal);
              if (isNan) return;
              const numVal = isNan ? 0 : numberVal;
              clearError(nameof<BusinessInfo>(p => p.paperBagFee));

              setFormData({
                ...formData,
                paperBagFee: numVal
              })

            }}
          />
        } />

      <LabelRow label="Order Reminder Phone Number"
        validationError={validationErrors.find(ve => ve.fieldName === nameof<BusinessInfo>(p => p.orderReminderPhoneNumber))}
        content={
          <TextField type="text"
            name={nameof<BusinessInfo>(p => p.orderReminderPhoneNumber)}
            value={getFormattedPhoneNumber(formData?.orderReminderPhoneNumber)}
            inputProps={{
              min: 0,
              maxLength: 14,
              step: minPaperBagFee,
            }}
            helperText={orderReminderPhoneNumberHelperText}
            onChange={e => {
              if (!formData) return;
              const val = e.target.value;
              clearError(nameof<BusinessInfo>(p => p.orderReminderPhoneNumber));

              setFormData({
                ...formData,
                orderReminderPhoneNumber: normalizeFormattedPhoneNumber(val)
              })
            }}
          />
        } />

      <LabelRow label="Order Reminder Phone Number Wait Time Minutes"
        content={
          <TextField type="number"
            name={nameof<BusinessInfo>(p => p.orderReminderPhoneNumberWaitTimeMinutes)}
            value={formData?.orderReminderPhoneNumberWaitTimeMinutes ?? 0}
            inputProps={{
              min: 0,
            }}
            helperText={orderReminderPhoneNumberWaitTimeMinutesHelperText}
            onChange={handleChange}
          />
        } />

      <LabelRow label="Catering Maximum Days to Order"
        content={
          <TextField type="number"
            name={nameof<BusinessInfo>(p => p.preOrderPrepMaxDays)}
            value={formData?.preOrderPrepMaxDays ?? 0}
            inputProps={{
              min: 0,
            }}
            helperText={preOrderPrepMaxDaysHelperText}
            onChange={handleChange}
          />
        } />

      <LabelRow label="Catering Minimum Days to Order"
        content={
          <TextField type="number"
            name={nameof<BusinessInfo>(p => p.preOrderPrepMinDays)}
            value={formData?.preOrderPrepMinDays ?? 0}
            inputProps={{
              min: 0,
            }}
            helperText={preOrderPrepMinDaysHelperText}
            onChange={handleChange}
          />
        } />

      <LabelRow label="Catering Note to Customers"
        content={
          <TextareaAutosize value={formData?.preOrderLimitWarning ?? ""}
            name={nameof<BusinessInfo>(p => p.preOrderLimitWarning)}
            onChange={handleChange}
          />
        } />


      <LabelRow label="Defatul SubTotal Tax"
        content={<TextField
          name={nameof<BusinessInfo>(p => p.tax)}
          value={formData?.tax ?? ""}
          helperText={"e.g GST, HST, etc."}
          onChange={handleChange}
          type="number"
        />}
      />

      <LabelRow label="Defatul Delivery Tax"
        content={<TextField
          name={nameof<BusinessInfo>(p => p.deliveryTax)}
          value={formData?.deliveryTax ?? ""}
          helperText={"e.g GST, HST, etc."}
          onChange={handleChange}
          type="number"
        />}
      />

    </div>
  )
}

export default OrderingEdit;
