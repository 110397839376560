import TextField from '@material-ui/core/TextField';
import { FilterOptionsState } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FocusEventHandler } from 'react';

export interface LabelsAutocompleteProps<T> {
  value: T[];
  options: T[];
  nameSelector: (item: T) => string;
  className?: string;
  inputLabel?: string;
  placeholder?: string;
  onChange?: (event, newValue) => void;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[];
}

const LabelsAutocomplete = <T extends Object>(props: LabelsAutocompleteProps<T>) => {
  const {
    value, options, inputLabel, placeholder, className,
    filterOptions, onChange, nameSelector, onBlur
  } = props;

  return (
    <Autocomplete
      multiple
      className={className}
      value={value}
      options={options}
      disableCloseOnSelect
      filterSelectedOptions={true}
      filterOptions={filterOptions}
      onChange={onChange}
      getOptionLabel={nameSelector}
      onBlur={onBlur}
      renderInput={(params) => (
        <TextField {...params}
          variant="outlined"
          label={inputLabel}
          placeholder={value.length > 0 ? '' : placeholder}
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.09)'
          }}
        />
      )}
    />
  );
}

export default LabelsAutocomplete;
