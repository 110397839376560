import classes from "./BusinessConfigs.module.scss";
import BizConfigHeader from "./Header";
import FormGroup from "@material-ui/core/FormGroup";
import CheckboxLabels from "../../components/FormControls/CheckboxLabels";
import LabelRow from "./LabelRow";
import { nameof } from "ts-simple-nameof";
import { ChangeEvent } from "react";
import { daysMapping, timeNumberToStr } from "../../common/pageUtils";
import { BusinessInfo, WorkingHour } from "../../Types/Types";
import { Button, IconButton, Select } from '@material-ui/core';
import { byHalfHourOptions } from "../../components/FormRow/FormRow";
import { useBizConfigNavBlocking } from "../../hooks/useBizConfigNavBlocking";
import { SelectType } from "../../common/bizConfigUtils";
import { bizConfigLinks } from "../../components/SideNavigation/SideNavigation";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const getDayValue = (day: string, fieldName?: string, workingHour?: WorkingHour) => {
  if (!workingHour) return "";


  if (fieldName === undefined) {
    return timeNumberToStr(workingHour.startTime);
  }

  return timeNumberToStr(workingHour[fieldName]);
}

const options = [...byHalfHourOptions, "2359"];
const timeOptionSelects = options.filter(val => val).map((val: string) => {
  const displayValue = `${val.slice(0, 2)}:${val.slice(2, 4)}`;
  return (
    <option key={val ?? -1} value={val}>
      {displayValue}
    </option>
  )
})


const WorkingHours = () => {
  const {
    formData, setFormData, elements, handleChange, saveBusinessInfo
  } = useBizConfigNavBlocking();


  const handleDayCloseChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!formData) return;

    const day = event.target.name;
    if (!day) return;
    const workingHours = formData.workingHours.filter(item => item.days === day);
    if (!workingHours) {
      console.error(`Day is not found ${day}`);
      return;
    }

    for (const wh of workingHours) {
      wh.open = !event.target.checked;
    }

    setFormData({ ...formData });
  }

  const handleDayHourChange = (event: SelectType, fieldName: keyof WorkingHour, wh: WorkingHour) => {
    if (!formData) return;

    const value = event.target.value;
    wh[fieldName.toString()] = Number(value);
    setFormData({ ...formData });
  }


  const WorkingHoursColumns = Object.entries(daysMapping).map(([shortName, fullName], index, arr) => {
    if (!formData) return;


    const whList = formData?.workingHours?.filter(item => item.days === shortName);
    const whd = whList.at(-1);
    const isClosed = whd ? !whd.open : false;

    return (
      <div>

        <div key={shortName} className={classes.body_workingHours_row}>

          <div className={classes.body_workingHours_row_label}>
            {fullName}
            <div className={classes.addWorkingHourBtn}>
              <Button variant="contained"
                onClick={(e) => {
                  if (!whd) return;

                  const newWorkingHour = { ...whd };
                  newWorkingHour.startTime = whd.endTime;
                  newWorkingHour.endTime = 2359;

                  formData.workingHours.push(newWorkingHour)
                  setFormData({ ...formData });
                }}
              >
                Add Working Hours
              </Button>
            </div>
          </div>

          <div className={classes.hoursContainer}>
            {whList.map(wh => {
              const startTime = getDayValue(shortName, "startTime", wh);
              const endTime = getDayValue(shortName, "endTime", wh);

              return (<div className={classes.hourRow}>
                <Select native
                  value={startTime ? startTime : "0800"}
                  name={shortName}
                  disabled={isClosed}
                  onChange={(event) => handleDayHourChange(event, "startTime", wh)}
                >
                  {timeOptionSelects}
                </Select>
                
                <span>-</span>

                <Select native
                  value={endTime ? endTime : "2000"}
                  name={shortName}
                  disabled={isClosed}
                  onChange={(event) => handleDayHourChange(event, "endTime", wh)}
                >
                  {timeOptionSelects}
                </Select>

                <IconButton onClick={(e) => {
                  formData.workingHours = formData.workingHours.filter(x => x !== wh);
                  setFormData({ ...formData });
                }}>
                  <DeleteForeverIcon />
                </IconButton>
              </div>
              )
            })}
          </div>

          <CheckboxLabels
            label="Closed"
            name={shortName}
            className={classes.closedCheckbox}
            checkOnChange={handleDayCloseChange}
            value={isClosed} />

        </div>

        {index !== (arr.length - 1) ? <hr /> : ''}

      </div>
    );
  });



  return (
    <section className={classes.workingHours}>

      {elements.ConfirmPopup}
      {elements.SnackbarElement}
      <BizConfigHeader title={bizConfigLinks.workingHour.name} onSave={saveBusinessInfo} />

      <FormGroup row className={classes.body_workingHours}>

        <label className={classes.body_workingHours_label}>
          Working Hours
        </label>

        <div className={classes.rows}>
          {WorkingHoursColumns}
        </div>

      </FormGroup>


      <LabelRow
        label={
          <>
            Closed Reason
            <FormGroup row className={classes.inner_row}>
              <CheckboxLabels
                label="Temporarily Closed"
                name={nameof<BusinessInfo>(p => p.storeTemporarilyClosed)}
                checkOnChange={handleChange}
                value={formData?.storeTemporarilyClosed ?? false}
              />
            </FormGroup>
          </>}
        content={
          <textarea value={formData?.storeTemporarilyClosedReason ?? ""}
            name={nameof<BusinessInfo>(p => p.storeTemporarilyClosedReason)}
            onChange={handleChange}
          />
        }
      />

    </section>
  )
}

export default WorkingHours;
