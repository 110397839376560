import { useState, FC, ChangeEvent } from 'react';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import classes from "./FormControls.module.scss";
import './MaterialOverride.scss';
import { useTypedSelector } from '../../hooks/useTypedSelector';


interface CheckboxProps {
  checkOnChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  label: string;
  name?: string;
  theme?: string;
  value?: boolean;
  className?: string;
  labelPlacement?: FormControlLabelProps["labelPlacement"]
}

const CheckboxLabels: FC<CheckboxProps> = (props) => {
  const {
    checkOnChange, label, theme, value, name, className,
    labelPlacement = "end"
  } = props;
  const { businessInfo } = useTypedSelector(state => state.businessInfo);

  const checkBoxStyle = () => {
    if (!businessInfo?.theme) return {};
    if (value)
      return { color: theme ?? businessInfo?.theme?.color }
  }

  return (

    <FormControlLabel
      control={
        <Checkbox checked={value} size="small"
          style={checkBoxStyle()}
          onChange={checkOnChange} name={name}
        />
      }
      className={className}
      labelPlacement={labelPlacement}
      label={<span className={classes.customize_checkbox_label}>{label}</span>}
    />

  );
}

export default CheckboxLabels;