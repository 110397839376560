import classes from './OptionList.module.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useActions } from '../../../hooks/useActions';
import { MenuEditUrlParams } from '../../../components/PortalHeader';
import { ChoiceDTO, ChoiceListType, ChoiceListValues, CustomizationSectionDTO, NameString, ProductOptionDTO } from '../../../Types/Types';
import { Button } from 'semantic-ui-react';
import { CustomizationUpdateDTO, getOption, updateCustomization } from '../../../api/optionsList';
import { Paper, Table, TableBody, TableCell, TableRow, Typography, TableHead, IconButton, Box } from '@material-ui/core';
import OkCancelPopup from '../../../components/Popup/OkCancelPopup';
import CustomizationForm from '../../../components/OptionForms/CustomizationForm';
import FormRow from '../../../components/FormRow/FormRow';
import { formatMoney, ISortable, reorderList } from '../../../common/pageUtils';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import {
  closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors
} from '@dnd-kit/core';
import SortableTableRow from '../SortableTableRow';
import CenteredCell from '../../../components/Tables/CenteredCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import SortableTable from '../SortableTable';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import { choiceSoldOutStyle } from '../../../constants/inlineStyles';
import { useCustomizationTemplates } from '../../../hooks/useTemplates';
import { red, yellow } from '@material-ui/core/colors';
import { nameof } from 'ts-simple-nameof';
import CheckboxLabels from '../../../components/FormControls/CheckboxLabels';

const newDefaultSection: ChoiceSection = {
  message: "",
  choices: [],
  sectionType: "anyOf",
  sortingOrder: 0,
};

type doThisDelegate = (choiceList: ChoiceDTO[], index: number, choiceType?: ChoiceListType)
  => void;
type ChoiceSection = CustomizationSectionDTO;

const defaultChoice: ChoiceDTO = {
  name: "",
  soldout: false,
  price: undefined,
  value: undefined,
  type: "boolean",
  unitOfMeasurement: "",
  customizationItemType: "",
  icon: "",
  sortingOrder: 0,
};

const OptionCustomization: React.FC = () => {
  const { setAppLoading, setAppLoaded } = useActions();
  const { optionId } = useParams<MenuEditUrlParams>();
  const [selectedChoice, setSelectedChoice] = useState<ChoiceDTO>();
  const [choiceSectionIndex, setChoiceListIndex] = useState<number>(0);
  const [choiceSection, setChoiceList] = useState<ChoiceSection>();
  const [openEditAddModal, setEditAddModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [openDeleteSectionConfirm, setOpenDeleteSectionConfirm] = useState(false);
  const [openAddSectionPopup, setOpenSectionTitleEditPopup] = useState(false);
  const [isSubmitSectionPressed, setIsSubmitSectionPressed] = useState(false);
  const templates = useCustomizationTemplates();

  const [selectedSectionRadio, setSelectedSectionRadio] = useState<ChoiceListType | "template">();
  const [selectedTemplateId, setSelectedTemplateId] = useState<number>(0);

  const [optionName, setOptionName] = useState("");
  const [formData, setFormData] = useState<CustomizationUpdateDTO>({
    id: 0,
    customization: {
      customizationLabel: "",
      optionSpecialRequest: "",
      anyOf: {
        message: "",
        choices: [],
        sortingOrder: 0,
      },
      oneOfList: []
    }
  });

  const customization = formData.customization;
  const fetchOptionAsync = useCallback(async () => {
    if (!optionId) return;
    const res = await getOption(optionId);
    const option = res.data as ProductOptionDTO;
    if (!option.customization) return;
    setOptionName(option.label);

    const anyOf = option.customization.anyOf ?? customization.anyOf;

    const newData: CustomizationUpdateDTO = {
      ...formData,
      customization: {
        customizationLabel: option.customization.customizationLabel ?? customization.customizationLabel,
        anyOf,
        oneOfList: option.customization.oneOfList ?? customization.oneOfList,
        optionSpecialRequest: option.customization.optionSpecialRequest ?? customization.optionSpecialRequest,
      },
      id: option.id ?? -1
    };

    const newCustomization = newData.customization;
    const oneOfList = newCustomization.oneOfList;
    const IsIncorrectOneOfListOrder = !oneOfList.some(list => list.sortingOrder > 0);
    oneOfList.forEach((choiceList, listIndex) => {

      if (IsIncorrectOneOfListOrder) {
        choiceList.sortingOrder = listIndex;
      }
      choiceList.choices.forEach((choice, ind) => {
        choice.tax = choice.tax ?? 0;
      })

      if (!choiceList.choices.some(option => option.sortingOrder > 0)) {
        choiceList.choices.forEach((choice, ind) => {
          choice.sortingOrder = ind;
        })
      }
    })

    setFormData(newData);
  }, [optionId])


  useEffect(() => {
    if (openEditAddModal) return;
    if (openAddSectionPopup) return;

    setAppLoading();
    fetchOptionAsync()
      .finally(setAppLoaded);
  }, [fetchOptionAsync, openEditAddModal, openAddSectionPopup])


  const onCancel = (e) => {
    setOpenDeleteConfirm(false);
    setOpenDeleteSectionConfirm(false);
    setOpenSectionTitleEditPopup(false);
  }

  const onDeleteCancel = (e) => {
    onCancel(e);
  }

  const findChoiceAndDo = (choice: ChoiceDTO, doThis: doThisDelegate) => {

    const oneOfList = customization.oneOfList.find(
      list => list.choices.find(c => c === choice)
    );
    const oneOfIndex = oneOfList?.choices.indexOf(choice);
    if (oneOfList && oneOfIndex !== undefined && oneOfIndex !== -1) {
      doThis(oneOfList?.choices, oneOfIndex, "oneOf");
    }
  }

  const onDeleteOk = (e) => {
    if (!selectedChoice) return;

    const doThis: doThisDelegate = (choiceList, index) => {
      choiceList.splice(index, 1);
    };
    findChoiceAndDo(selectedChoice, doThis);

    trySubmitData();
  }

  // TODO favicon

  const onAddSaveSection = (e) => {
    if (!choiceSection) return;

    const showTemplate = !isEdit && selectedSectionRadio === "template";
    const showNoTemplateSelectedMsg = showTemplate && selectedTemplateId === 0;
    if (showNoTemplateSelectedMsg) {
      setIsSubmitSectionPressed(true);
      return;
    }

    if (choiceSectionIndex === -1) {
      // customization.anyOf = choiceList;
    } else if (choiceSectionIndex >= 0 && customization.oneOfList[choiceSectionIndex]) {
      customization.oneOfList[choiceSectionIndex] = choiceSection;
    }


    trySubmitData();
  }

  const onDeleteSectionOk = (e) => {
    if (choiceSectionIndex === undefined) return;

    const oneOfIndex = customization.oneOfList[choiceSectionIndex];

    if (oneOfIndex) {
      customization.oneOfList.splice(choiceSectionIndex, 1);
    }

    trySubmitData();
  }

  const trySubmitData = async (choice?: ChoiceDTO, choiceType?: ChoiceListType, originalData?: ChoiceDTO) => {
    setAppLoading();

    const isAvailableToSoldout = originalData?.soldout === false && choice?.soldout;

    const setSelectedValues = (choice: ChoiceDTO) => {
      if (choiceType !== "oneOf") return;
      const oneOfList = customization.oneOfList
        .find(list => list.choices.find(ch => ch === choice))
      oneOfList?.choices.forEach(ch => {
        if (!choice.value || choice.value === 0) return;
        if (ch === choice && choice.value === 1) return;
        ch.value = 0;
      })
    }

    const setSoldoutValues = (choice: ChoiceDTO) => {
      if (choiceType !== "oneOf") return;
      if (!originalData) return;
      if (isAvailableToSoldout === false) return;

      const oneOfList = customization.oneOfList
        .find(list => list.choices.find(ch => ch === choice))

      const firstAvailable = oneOfList?.choices.filter(ch => ch !== choice && ch.soldout === false)[0];
      if (firstAvailable) {
        firstAvailable.value = 1;
      }
    }

    if (choice) {

      choice.price = Number(choice.price);

      if (isAvailableToSoldout) {
        choice.value = 0;
        setSoldoutValues(choice);
      } else {
        setSelectedValues(choice);
      }
    }

    try {
      const res = await updateCustomization(formData);
      if (res.status === 200) {
        setEditAddModal(false);
        setOpenDeleteConfirm(false);
        setOpenDeleteSectionConfirm(false);
        setOpenSectionTitleEditPopup(false);
        setSelectedTemplateId(0);
        // setSelectedSectionRadio(undefined)
      }
    } catch (err) {
      console.error("Something went wrong with updating the customization", err);
    } finally {
      setAppLoaded();
    }
  }

  const cloneChoice = (choice: ChoiceDTO) => {
    findChoiceAndDo(choice, (choiceList, index, choiceType) => {
      const clonedChoice = { ...choice };
      if (choiceType === "oneOf") {
        clonedChoice.value = 0;
      }
      choiceList.splice(index + 1, 0, clonedChoice);
      choiceList.forEach((choice, ind) => {
        choice.sortingOrder = ind;
      })
    });

    trySubmitData();
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const changeOrder = <T extends ISortable>(event, choiceList: T[]) => {
    const { active, over } = event;
    const oldIndex = Number(active.id);
    const newIndex = Number(over.id);
    if (oldIndex === newIndex) return;

    const sortedList = reorderList(choiceList, oldIndex, newIndex);

    setFormData({ ...formData });
    trySubmitData();
  }

  const choicesRows = (choices: ChoiceDTO[]) => {
    return (
      <DndContext
        onDragEnd={event => changeOrder(event, choices)}
        collisionDetection={closestCenter}
        sensors={sensors}>
        <SortableContext
          items={choices.map(choice => choice.sortingOrder.toString())}
          strategy={verticalListSortingStrategy}
        >
          {choices.map((choice: ChoiceDTO, ind) =>
            <SortableTableRow
              key={`${choice.sortingOrder}_${ind}`}
              item={choice}
              className={classes.customize_row}
              sortIndex={choice.sortingOrder.toString()}
              content={(choice: ChoiceDTO, listeners) => {
                const isOption = choice.type === "boolean";
                const isNumber = choice.type === "number";

                const isCustomizationFree = !choice.price || choice.price === 0;
                const formattedMoney = formatMoney(choice.price);

                return (<>
                  <CenteredCell className={classes.customize_row_nameValue}>
                    <span style={choice.soldout ? choiceSoldOutStyle : {}}>
                      {choice.name}
                    </span>
                  </CenteredCell>

                  <CenteredCell>
                    {isNumber && choice.value !== 0 && choice.value}
                    {isOption &&
                      (choice.value === 1
                        ? <FontAwesomeIcon icon={faCheck} size="2x" />
                        : <div />)}
                  </CenteredCell>

                  <CenteredCell>
                    {isCustomizationFree ? "Free" : `$${formattedMoney}`}
                  </CenteredCell>

                  <CenteredCell>
                    {choice.customizationItemType}
                  </CenteredCell>

                  <CenteredCell>
                    <Button
                      content="Clone"
                      onClick={e => cloneChoice(choice)} />
                    <Button
                      content="Edit"
                      onClick={e => {
                        choice.price = formattedMoney;
                        setSelectedChoice(choice);
                        setIsEdit(true);
                        setEditAddModal(true);
                      }} />
                    <Button
                      content="Remove"
                      className={classes.customize_deleteButton}
                      onClick={e => {
                        setSelectedChoice(choice);
                        setOpenDeleteConfirm(true);
                      }}
                    />
                  </CenteredCell>
                </>)
              }}
            />)}
        </SortableContext>
      </DndContext>
    )
  };

  const choiceTableHeader = (choiceList: ChoiceSection, ind: number, listeners?) => {
    return (
      <TableHead>
        <TableRow>

          <TableCell className={classes.optionList_title}>
            <Typography className={classes.label}>
              Section Title
            </Typography>
            <Typography className={classes.section} noWrap={false}>
              {choiceList?.message}
            </Typography>
          </TableCell>

          <TableCell /> {/* for Value column */}
          <TableCell /> {/* for Price column */}

          <TableCell>

          </TableCell>

          <TableCell>
            <div className={classes.optionList_section_actions}>
              <div>
                <Button
                  content="Edit Section"
                  onClick={e => {
                    setIsEdit(true);
                    setChoiceListIndex(ind !== undefined ? ind : -1);
                    setChoiceList(choiceList);
                    setOpenSectionTitleEditPopup(true);
                  }} />
                <Button
                  content="Remove Section"
                  className={classes.customize_deleteButton}
                  onClick={e => {
                    setChoiceListIndex(ind !== undefined ? ind : -1);
                    setChoiceList(choiceList);
                    setOpenDeleteSectionConfirm(true);
                  }}
                />
              </div>

              <IconButton {...listeners}>
                <ViewWeekIcon />
              </IconButton>
            </div>
          </TableCell>

        </TableRow>

        <TableRow>
          <CenteredCell>Name</CenteredCell>
          <CenteredCell>Value</CenteredCell>
          <CenteredCell>Price</CenteredCell>
          <CenteredCell>Customization Type</CenteredCell>
          <TableCell >
            <Button
              content="Add Customization Item"
              onClick={e => {
                setIsEdit(false);
                const newChoice = { ...defaultChoice };
                newChoice.sortingOrder = choiceList.choices.length;
                choiceList.choices.push(newChoice);
                setSelectedChoice(newChoice);
                setFormData(formData)
                setEditAddModal(true);
              }}
            />

          </TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const anyModalOpen = openEditAddModal || openDeleteConfirm
    || openAddSectionPopup || openDeleteSectionConfirm;

  const choicesTable = (choiceList: ChoiceSection, ind: number) => (
    <SortableTable
      key={`${choiceList.sortingOrder}_${ind}`}
      item={choiceList}
      className={classes.table_container}
      sortIndex={ind.toString()}
      content={(oneOfListList: ChoiceSection, listeners) => {
        return (
          <Table aria-label="sticky table"
            stickyHeader={!anyModalOpen}
          >
            {choiceTableHeader(choiceList, ind, listeners)}

            <TableBody>
              {choiceList && choicesRows(choiceList.choices)}
            </TableBody>

          </Table>
        )
      }}
    />
  );

  const sectionTypeOptions: NameString[] = useMemo(() => {
    const options = Object.entries(ChoiceListValues).map((entry) => {
      return { name: entry[1], value: entry[0] } as NameString;
    })

    options.push({
      name: "Template",
      value: "template"
    })

    return options;
  }, []);

  const oneOfList = customization.oneOfList;

  const SectionForm = useMemo(() => {

    const showTemplate = !isEdit && selectedSectionRadio === "template";
    const showNoTemplateSelectedMsg = showTemplate && isSubmitSectionPressed && selectedTemplateId === 0;
    const hasTemplates = templates.length > 0;
    const sectionTitleInputWidth = 460;

    const FormContent = <span className={classes.newSection}>

      <FormRow wide
        name='section_title_text'
        label="Section Title"
        inputType="text"
        value={choiceSection?.message}
        maxLength={50}
        inputStyle={{ minWidth: sectionTitleInputWidth }}
        handleChange={event => {
          if (!choiceSection) return;
          choiceSection.message = event.target.value?.toString() ?? '';
          setChoiceList({ ...choiceSection });
        }} />

      {!isEdit ? <FormRow<NameString> wide
        label='Section type'
        name='selected_section_radio'
        inputType="radio"
        value={selectedSectionRadio}
        options={sectionTypeOptions}
        valueField={radioOption => radioOption.value}
        displayField={radioOption => radioOption.name}
        handleChange={event => {
          const value = event.target.value as ChoiceListType;
          setSelectedSectionRadio(value);

          if (!choiceSection) return;
          if (value.toString() === "template") return;
          choiceSection.sectionType = value;
          setChoiceList({ ...choiceSection });
        }} /> : ""}

      {showTemplate && hasTemplates ? <FormRow wide
        name='customization_section_template_name_dropdown'
        inputType="dropdown"
        value={selectedTemplateId}
        options={templates}
        displayField={(template) => template.customizationSectionTemplatName ?? ""}
        valueField={(template) => template.customizationSectionTemplateId ?? ""}
        handleChange={(e) => {
          const template = templates.find(x => x.customizationSectionTemplateId === e.target.value);
          if (!template?.customizationSectionTemplateJson) return;
          const customizationJSON = JSON.parse(template.customizationSectionTemplateJson) as ChoiceSection;
          setSelectedTemplateId(template.customizationSectionTemplateId);
          setChoiceList(customizationJSON)
          setIsSubmitSectionPressed(false);
        }}
      /> : ""}

      {showTemplate && !hasTemplates ? <span style={{ color: yellow[700] }}>No templates found to choose from</span> : ""}
      {showTemplate && showNoTemplateSelectedMsg ? <span style={{ color: red[700] }}>You must select a template</span> : ""}

      <Box className={classes.minMaxConfig}>

        <CheckboxLabels
          label="Mininmum selection"
          name={nameof<CustomizationSectionDTO>(p => p.min)}
          value={!!choiceSection?.min ? true : false}
          checkOnChange={event => {
            if (!choiceSection) return;
            choiceSection.min = event.target.checked ? 1 : 0;
            setChoiceList({ ...choiceSection });
          }}
        />

        {!!choiceSection?.min ? <FormRow wide
          inputType="number"
          name={nameof<CustomizationSectionDTO>(p => p.min)}
          value={choiceSection?.min}
          inputStyle={{ minWidth: sectionTitleInputWidth }}
          handleChange={event => {
            if (!choiceSection) return;
            const value = event.target.value;
            if (value === undefined || value === null) return;
            choiceSection.min = +value;
            setChoiceList({ ...choiceSection });
          }} /> : <></>}


        <CheckboxLabels
          label="Maximum selection"
          name={nameof<CustomizationSectionDTO>(p => p.max)}
          value={!!choiceSection?.max ? true : false}
          checkOnChange={event => {
            if (!choiceSection) return;
            choiceSection.max = event.target.checked ? 1 : 0;
            setChoiceList({ ...choiceSection });
          }}
        />

        {!!choiceSection?.max ? <FormRow wide
          inputType="number"
          name={nameof<CustomizationSectionDTO>(p => p.max)}
          value={choiceSection?.max}
          inputStyle={{ minWidth: sectionTitleInputWidth }}
          handleChange={event => {
            if (!choiceSection) return;
            const value = event.target.value;
            if (value === undefined || value === null) return;
            choiceSection.max = +value;
            setChoiceList({ ...choiceSection });
          }} /> : <></>}


        <CheckboxLabels
          label="Allow customers to select an item more than once"
          name={nameof<CustomizationSectionDTO>(p => p.multiMax)}
          value={!!choiceSection?.multiMax ? true : false}
          checkOnChange={event => {
            if (!choiceSection) return;
            choiceSection.multiMax = event.target.checked ? 1 : 0;
            setChoiceList({ ...choiceSection });
          }}
        />

      </Box>

    </span>;

    return <OkCancelPopup
      isOpen={openAddSectionPopup}
      onCancel={onCancel}
      onOk={onAddSaveSection}
      popupStyle={{ minWidth: sectionTitleInputWidth + 140, }}
      okButtonContent={isEdit ? "Save" : "Add"}
      title={`${isEdit ? "Save" : "Add"} Section`}
      content={FormContent} />;

  }, [openAddSectionPopup, isEdit, choiceSection, selectedSectionRadio, selectedTemplateId,
    isSubmitSectionPressed
  ]);

  return (
    <section className={classes.customize}>
      {openEditAddModal &&
        <CustomizationForm
          showModal={openEditAddModal}
          setModalOpen={setEditAddModal}
          isEdit={isEdit}
          choice={selectedChoice}
          setFormData={setFormData}
          customization={customization}
          tryUpdate={trySubmitData}
        />}

      <h1>
        {/* TITLE OF THE PAGE */}
        {optionName} Customization
      </h1>

      <Paper color="red" className={classes.customize_oneOfTables}>
        <DndContext
          onDragEnd={event => changeOrder(event, oneOfList)}
          collisionDetection={closestCenter}
          sensors={sensors}>
          <SortableContext
            items={oneOfList.map((choice, ind) => ind.toString())}
            strategy={verticalListSortingStrategy}
          >
            {oneOfList.map((list, ind) => choicesTable(list, ind))}
          </ SortableContext>
        </ DndContext>

        <div className={classes.customize_footer}>
          <Button onClick={e => {
            setIsEdit(false);
            const newData = { ...formData };
            newDefaultSection.sortingOrder = oneOfList.length;
            const newLength = newData.customization.oneOfList.push(newDefaultSection);
            setFormData(newData);
            setChoiceList(newDefaultSection);
            setChoiceListIndex(newLength - 1);
            setOpenSectionTitleEditPopup(true);
          }}>
            Add Section
          </Button>
        </div>
      </Paper>

      {SectionForm}

      <OkCancelPopup
        isOpen={openDeleteConfirm}
        onCancel={onDeleteCancel}
        onOk={onDeleteOk}
        title="Please Confirm"
        content="Do you really want to delete this customization?"
      />
      <OkCancelPopup
        isOpen={openDeleteSectionConfirm}
        onCancel={onDeleteCancel}
        onOk={onDeleteSectionOk}
        title="Please Confirm"
        content="Do you really want to delete this customization section?"
      />

    </section >
  );
};

export default OptionCustomization;
