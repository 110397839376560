import { ChangeEvent } from "react";

export type Channel = 'normal' | 'kiosk' | 'catering' | 'admin' | 'menu';


export type DiningOptions = {
  delivery: string;
  dinein: string;
  takeout: string;
  preorder: string;
  dineinDesc?: string;
  takeoutDesc?: string;
  preorderDesc?: string;
  deliveryDesc?: string;
  dineinPrepTimeMinimum?: number;
  takeoutPrepTimeMinimum?: number;
  preorderPrepTimeMinimum?: number;
  deliveryPrepTimeMinimum?: number;
};

export type BusinessInfo = {
  announcement: { message: string, show: string };
  businessId: string;
  customerContactInfo: "required" | "optional";
  customerNameRequired: boolean;
  diningOption: DiningOptions;
  emailOrderToRestaurant: boolean;
  env: string;
  garageSalePercentage: number;
  logo: string;
  pickupInstruction: string;
  menuOnly: boolean;
  mobilePaydiscount: number;
  mobilePaymentDisabled: boolean;
  payInPersonDisabled?: boolean;
  name: string;
  overallDiscounts?: DiscountRuleDTO[],
  payKey: string;
  paymentGateway: string;
  googleMapUri: string;
  websiteUri: string;
  instagramUri: string;
  facebookUri: string;
  prepTimeConfig: {
    defaultWaitingMinutes: number;
    incrementPerQueueMinutes: number;
    secondsPerDollar: number;
  };


  productListFilter: boolean;
  serviceProviderUserEmail: string;
  serviceProviderUserId: string;
  specialRequestHint: string;
  squareApplicationId: any;
  squareLocationId: any;
  storeOpen: boolean;
  storeClosedReason?: string;
  storeTemporarilyClosed: boolean;
  storeTemporarilyClosedReason?: string;
  theme: {
    color: string;
    font: string;
    buttonBackgroundColor: string;
    buttonSelectedColor: string;
  };
  tip: number[];
  version: number;
  workingHours: WorkingHour[];
  businessLabels: BizLabel[];
  verifyPhone?: string;
  paperBagFee?: number | string;
  textToVoiceBusinessName?: string;
  orderReminderPhoneNumber?: string;
  orderReminderPhoneNumberWaitTimeMinutes?: number;
  location: Address;
  preOrderLimitWarning?: string;
  preOrderPrepMaxDays?: number;
  preOrderPrepMinDays?: number;
  menuItemCollapsible?: boolean;

  addressLatitude?: string;
  addressLongitude?: string;

  deliveryUnavailableForAddressMessage?: string;
  deliveryDistance?: number;
  deliveryDuration?: number;

  minOrderAmountForFreeDelivery?: number;
  deliveryPrepTimeMinimum?: number;
  excludedCities?: string;

  minimumDeliveryFee?: number;
  firstDeliveryKilometersRange?: number;

  perExtraKilometerFee?: number;
  serviceProviderUserPhone?: number;

  tax?: number;
  deliveryTax?: number;
};

export interface Address {
  addressId?: number;
  linkId?: number;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  crossCountryUnion: string;
  googleMapUri: string;
  postalCode: string;
  province: string;
  state: string;
  suiteNumber: string;
  createDate?: null;
  updateDate?: string;
}

export interface WorkingHour {
  days: string;
  startTime: number;
  endTime: number;
  open: boolean;
}

export type CustomDetailType = {
  name: string;
  type: string; //?
  unitOfMeasurement: string;
  title: string;
  value: number;
  price: number;
};

export type MenuByCategory = {
  [k: string]: MenuProductDTO[];
};

export type credentialPayload = {
  signInId: string;
  password: string;
  serviceProviderId: string;
}

export type Breadcrumb = {
  displayName?: string;
  pathName: string;
}

export type FoodCategory = {
  id: number;
  name: string;
  description?: string;
  defaultProductImage: string;
}

export enum DISCOUNT_VALUE_TYPE {
  percentage = "percentage",
  amount = "amount",
  bogo = "bogo",
}
export enum DISCOUNT_TYPE {
  dineIn = "dine-in",
  takeOut = "take-out",
  preOrder = "pre-order",
  mobilePay = "mobilePay",
  credit = "credit",
  promoCode = "promoCode",
}

export interface MenuCategoryDTO {
  id?: number
  name: string;
  description: string;
  visibilityRuleJson: VisibilityRulesDTO
  selected?: boolean;
  serviceProviderUserId?: number;
  defaultProductImage?: string;
}

export interface VisibilityRulesDTO {
  startTime?: number;
  endTime?: number;
  startDate: string;
  endDate: string;
  days: string[];
  type: string | MENU_CATEGORY_TYPE;
}

export interface CategoryFormData {
  visible?: boolean;
  id?: number
  name: string;
  description: string;
  visibilityRules: VisibilityRules
  selected?: boolean;
  serviceProviderUserId?: number;
  defaultProductImage?: string;
}

export interface CategoryFormData {
  visible?: boolean;
  id?: number
  name: string;
  description: string;
  selected?: boolean;
  serviceProviderUserId?: number;
  defaultProductImage?: string;
  visibilityRules: VisibilityRules
}

export interface DaysCheckboxesType {
  Sun: boolean;
  Mon: boolean;
  Tue: boolean;
  Wed: boolean;
  Thu: boolean;
  Fri: boolean;
  Sat: boolean;
}

export interface VisibilityRules extends DaysCheckboxesType {
  startTime?: string;
  endTime?: string;
  startDate: string;
  endDate: string;
  type: string | MENU_CATEGORY_TYPE;
}


export enum MENU_CATEGORY_TYPE {
  takeout = "Takeout",
  summer = "Summer",
}

export type MenuProductDTO = {
  id?: number,
  name: string,
  description: string,
  image?: string,
  category: number,
  featured: boolean,
  serviceProviderUserId: number,
  sortingOrder: number,
  optionsType?: string,
  options?: ProductOptionDTO[],
  visibilityRuleJson: VisibilityRulesDTO;
}

export interface ProductFormData {
  id?: number
  name: string;
  category: number,
  description: string;
  serviceProviderUserId: number;
  featured: boolean,
  visibilityRules: VisibilityRules
  sortingOrder?: any,
  image?: string;
  optionsType?: string,
  visible?: boolean;
}

export type ProductOptionDTO = {
  id?: number,
  productId: number,
  label: string,
  price: number,
  sortingOrder: number,
  selected?: boolean,
  soldout: boolean,
  optionImage?: string,
  optionDescription?: string,
  inventory?: InventoryDTO,
  reloadConfig?: ReloadConfigDTO,
  discountRule?: DiscountRuleDTO,
  customization?: CustomizationDTO,
  visibilityRuleJson: VisibilityRulesDTO
  locationAvailabilities: LocationsDTO[]
}

export type LocationsDTO = {
  businessId?: number;
  businessName?: string;
  showInMenu?: boolean;
  soldout?: boolean;
}

export type OptionFormData = {
  id?: number,
  productId: number,
  sortingOrder: number,
  label: string,
  price?: number,
  tax?: number,
  discountRule?: DiscountRuleDTO,
  selected?: boolean,
  soldout: boolean,
  forceCustomizationBeforeAdd: boolean,
  optionImage?: string,
  optionDescription?: string,
  inventory?: InventoryDTO,
  reloadConfig?: ReloadConfigDTO,
  customization?: CustomizationDTO,
  type?: string,
  addCondition?: string,
  visibilityRules: VisibilityRules
  locationAvailabilities: LocationsDTO[]
}


type DiscountRuleBase = {
  title?: string;
  type: DISCOUNT_VALUE_TYPE;
  minPurchase?: number | string;
  maxPurchase?: number | string;
  redeemTimesLimit?: number;
  discountType?: string;
  startDate: string;
  endDate: string;
}

export interface DiscountRuleDTO extends DiscountRuleBase {
  days: string[],
  value: number,
  startTime: number | undefined,
  endTime: number | undefined,
}

export type DiscountRuleFormData = {
  value: number | string,
} & VisibilityRules & DiscountRuleBase

export type InventoryDTO = {
  availableQty?: number,
  shelfLife?: number,
  minimumQtyAlert?: number,
  updateDate?: string
}

export interface ReloadConfigDTO {
  reloadSize?: number;
  lowStockLimit?: number;
  maxAvailabileQty?: number;
  days?: string[];
}

export interface ReloadConfigFormData extends DaysCheckboxesType, Omit<ReloadConfigDTO, "days"> {

}

export interface CustomizationSectionDTO {
  message: string;
  choices: ChoiceDTO[];
  sectionType?: ChoiceListType;
  sortingOrder: number;
  min?: number,
  max?: number,
  multiMax?: number,
}

export type CustomizationDTO = {
  customizationLabel: string,
  optionSpecialRequest: string,
  anyOf: CustomizationSectionDTO,
  oneOfList: CustomizationSectionDTO[]
}

export type ChoiceDTO = {
  name: string,
  soldout: boolean,
  type: "boolean" | "number",
  unitOfMeasurement: string,
  customizationItemType?: string,
  value?: number,
  price?: number | string,
  tax?: number,
  icon?: string
  sortingOrder: number;
}

export type NameBoolean = { name: string, value: boolean };
export type NameString = { name: string, value: string };
export type ChoiceListType = "anyOf" | "oneOf";
export enum ChoiceListValues {
  anyOf = "Quantity/Option",
  oneOf = "Single Choice"
}

export type Day = "Mon" | "Sun" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat";

export interface BusinessOrder {
  businessId: 1,
  serviceProviderUserId: number;
  userId?: number,
  orderId: number;
  orderType: string;
  selectedTipPercentage: number;
  subTotal: number;
  tax: number;
  tip: number;
  total: number;
  formattedSubTotal: number;
  formattedTotal: number;
  instructions: string;
  userPhoneNumber?: string;
  phoneOS: string;
  token: string;
  receiptEmail?: string,
  receiptSmsNumber?: string;
  paymentStatus: string;
  orderDateAndTime: number;
  createDate?: string;
  updateDate: string;
}

export interface BizLabel {
  businessLabel: string;
  businessLabelDesc: string;
  businessLabelId: number;
  createDate: string;
  updateDate: string;
  visitsCount: number;
}

export interface ValidationError {
  fieldName: string;
  errorMsg: string;
}

export type LocalVisibility = VisibilityRules | DiscountRuleFormData;

export type CheckBoxChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
