import { ReactNode, ChangeEvent } from "react";
import { updateBusinessInfo } from "../api/businessEdit";
import { minPaperBagFee } from "../appConfigs";
import { BusinessInfo } from "../Types/Types";
import { daysMapping, nullReplacer } from "./pageUtils";


export const cloneBusinessInfo = (businessInfo: BusinessInfo) => {

  const workingHours = businessInfo.workingHours ?? [];
  Object.keys(daysMapping).forEach(shortName => {
    const dayWorkingHour = workingHours.find(wh => wh.days === shortName);
    if (dayWorkingHour) return;
    workingHours.push({
      days: shortName,
      startTime: 800,
      endTime: 2000,
      open: true
    })
  })

  const clonedBI: BusinessInfo = {
    ...businessInfo,
    paperBagFee: businessInfo.paperBagFee ?? minPaperBagFee,
    paymentGateway: businessInfo.paymentGateway ?? "square",
    payInPersonDisabled: businessInfo.payInPersonDisabled ?? false,
    menuItemCollapsible: businessInfo.menuItemCollapsible ?? false,
    announcement: { ...businessInfo.announcement },
    diningOption: {
      ...businessInfo.diningOption,
      takeoutDesc: businessInfo.diningOption?.takeoutDesc ?? '',
      dineinDesc: businessInfo.diningOption?.dineinDesc ?? '',
      preorderDesc: businessInfo.diningOption?.preorderDesc ?? '',
      deliveryDesc: businessInfo.diningOption?.deliveryDesc ?? '',
      takeoutPrepTimeMinimum: businessInfo.diningOption?.takeoutPrepTimeMinimum ?? 0,
      dineinPrepTimeMinimum: businessInfo.diningOption?.dineinPrepTimeMinimum ?? 0,
      preorderPrepTimeMinimum: businessInfo.diningOption?.preorderPrepTimeMinimum ?? 0,
      deliveryPrepTimeMinimum: businessInfo.diningOption?.deliveryPrepTimeMinimum ?? 0,
    },
    deliveryPrepTimeMinimum: businessInfo.deliveryPrepTimeMinimum ?? 0,
    prepTimeConfig: { ...businessInfo.prepTimeConfig },
    workingHours: JSON.parse(JSON.stringify(workingHours)),
    theme: { ...businessInfo?.theme },
    overallDiscounts: [...businessInfo.overallDiscounts ?? []],
    tip: [...businessInfo?.tip ?? []],
    businessLabels: [...businessInfo?.businessLabels ?? []],
  }
  clonedBI.theme.color = clonedBI.theme.color || "#000000";
  return clonedBI;
}

export const postBusinessInfo = async (onSuccess: Function, onFail: Function, newBusinessData?: BusinessInfo) => {
  if (!newBusinessData) return;

  try {
    await updateBusinessInfo(newBusinessData);
    onSuccess();
  } catch (error) {
    console.error(error);
    onFail();
  }
};

export type SelectType = ChangeEvent<{ name?: string | undefined; value: unknown; }>;
export interface SnackbarSettings {
  message: ReactNode;
  severity: "success" | "error";
}

export const successSnackbar: SnackbarSettings = {
  severity: "success",
  message: "Save Success!",
}

export const errorSnackbar: SnackbarSettings = {
  severity: "error",
  message: "Something went wrong...",
}

export const validationErrorSnackbar: SnackbarSettings = {
  severity: "error",
  message: "Some fields have incorrect content.",
}

export const isEqual = (first: BusinessInfo, second: BusinessInfo) => {
  if (!first) return false;

  if (first?.workingHours) {
    if (!second?.workingHours) return false;
    for (const wh of first.workingHours) {
      const match = second.workingHours
        .find(x => {
          if (x.days !== wh.days) return false;
          if (x.startTime !== wh.startTime) return false;
          if (x.endTime !== wh.endTime) return false;
          if (x.open !== wh.open) return false;
          return true;
        });
      if (!match) return false;
    }
  }
  if (!second) return false;
  const { workingHours: firstWh, ...firstNormalized } = first;
  const { workingHours: secondWh, ...secondNormalized } = second;

  const originalJsonStr = JSON.stringify(firstNormalized, nullReplacer);
  const dirtiedJsonStr = JSON.stringify(secondNormalized, nullReplacer);
  return originalJsonStr === dirtiedJsonStr;
}

export const isDirty = (first, second) => {
  return !isEqual(first, second);
}
