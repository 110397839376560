import classes from './OptionForms.module.scss';
import GlobalModal from '../GlobalModal/GlobalModal';
import FormRow, { ChangeEventParams } from '../FormRow/FormRow';
import { nameof } from 'ts-simple-nameof';
import { useState, ChangeEvent, useEffect, CSSProperties } from "react";
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Button } from '@material-ui/core';
import { getOption, InventoryUpdateDTO, updateInventory } from '../../api/optionsList';
import { InventoryDTO, ProductOptionDTO, ReloadConfigDTO, ReloadConfigFormData } from '../../Types/Types';
import { daysFields, mapDaysToForm, nullReplacer } from '../../common/pageUtils';
import DaysCheckboxes from '../FormVisibilityRules/DaysCheckboxes';

interface InventoryFormData {
  id?: number;
  inventory?: InventoryDTO;
  reloadConfig: ReloadConfigFormData;
}

interface OptionInventoryFormProps {
  showModal: boolean;
  setModalOpen: Function;
  optionId?: number;
}

const OptionInventoryForm: React.FC<OptionInventoryFormProps> = (props) => {
  const { showModal, setModalOpen, optionId } = props;
  const { setAppLoading, setAppLoaded } = useActions();
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [validationErrors, setValidationErrors] = useState("");
  const [inventoryType, setInventoryType] = useState("manual");
  const [formData, setFormData] = useState<InventoryFormData>({
    id: optionId,
    inventory: {
      availableQty: 0,
      minimumQtyAlert: 0,
      shelfLife: 0,
      updateDate: "",
    },
    reloadConfig: {
      Sun: false, Mon: false,
      Tue: false, Wed: false,
      Thu: false, Fri: false, Sat: false,
      lowStockLimit: 0,
      maxAvailabileQty: 0,
      reloadSize: 0,
    }
  });

  const [originalData, setOriginalData] = useState<InventoryFormData>({
    ...formData,
  });

  const fetchOptionAsync = async () => {
    if (!optionId) return;
    const res = await getOption(optionId);

    const option = res.data as ProductOptionDTO;
    const reloadConfig = option.reloadConfig;
    const mappedData: InventoryFormData = {
      id: optionId,
      inventory: option.inventory ?? formData.inventory,
      reloadConfig: formData.reloadConfig
    }

    if (reloadConfig) {
      mappedData.reloadConfig.reloadSize = option.reloadConfig?.reloadSize;
      mappedData.reloadConfig.lowStockLimit = option.reloadConfig?.lowStockLimit;
      mappedData.reloadConfig.maxAvailabileQty = option.reloadConfig?.maxAvailabileQty;
      if (reloadConfig.days) {
        mapDaysToForm(reloadConfig.days, mappedData.reloadConfig);
      }
    }

    setFormData(mappedData);
    setOriginalData({ ...mappedData });
  }

  const fetchData = async () => {
    setAppLoading();
    await fetchOptionAsync();
    setAppLoaded();
  }

  useEffect(() => {
    fetchData();
  }, [optionId, businessInfo?.serviceProviderUserId]);

  const getFormDtoSnapshot = () => {

    const reloadConfig = formData.reloadConfig;
    const formDTO: InventoryUpdateDTO = {
      id: formData.id,
      inventory: formData.inventory,
      reloadConfig: {
        reloadSize: reloadConfig.reloadSize,
        lowStockLimit: reloadConfig.lowStockLimit,
        maxAvailabileQty: reloadConfig.maxAvailabileQty,
        days: daysFields.filter(day => reloadConfig[day] === true),
      }
    }
    return formDTO;
  }

  const onModalClose = (e) => {
    const originalJsonStr = JSON.stringify(originalData, nullReplacer);
    const dirtiedJsonStr = JSON.stringify(formData, nullReplacer);
    if (originalJsonStr !== dirtiedJsonStr) {
      setOpenConfirmPopup(true);
      return;
    }
    setModalOpen(false);
  }

  const onSave = async (e) => {
    if (!optionId) return;
    if (!validateForm()) return;

    const updatedInventory = getFormDtoSnapshot();

    try {
      setAppLoading();
      const res = await updateInventory(updatedInventory);
      if (res.data === "success") {
        setModalOpen(false);
      }
    } catch (err) {
      console.error("Something went wrong with updating the option", err);
    } finally {
      setAppLoaded();
    }
  }

  const onPopupOk = async (event) => {
    setOpenConfirmPopup(false);
    setModalOpen(false);
  }

  const onPopupClose = (event) => {
    setModalOpen(true);
    setOpenConfirmPopup(false);
  }

  const validateForm = () => {
    // if (formData.label.length === 0) {
    //   setValidationErrors("Option name is required!");
    //   return false;
    // }

    setValidationErrors("");
    return true;
  }

  const handleChange = (event: ChangeEventParams) => {
    const target = event.target;
    const nameProp = target.name;
    const checkedInputs = ["checkbox", "radio"]
    const value = checkedInputs.includes(target.type) ? target.checked : target.value;

    if (formData.inventory?.hasOwnProperty(nameProp)) {
      const newData: InventoryFormData = {
        ...formData,
        inventory: {
          ...formData.inventory,
          [nameProp]: value
        }
      }
      setFormData(newData);
      return;
    }

    if (formData.reloadConfig?.hasOwnProperty(nameProp)) {
      const newData: InventoryFormData = {
        ...formData,
        reloadConfig: {
          ...formData.reloadConfig,
          [nameProp]: value
        }
      }
      setFormData(newData);
      return;
    }

    console.warn(`No prop on inventory or reloadConfig called ${nameProp}!`);
  }

  const getActiveStyle = (value): CSSProperties => {
    if (inventoryType !== value) return {};
    return {
      backgroundColor: businessInfo?.theme?.color,
      color: "white"
    }
  }

  const manualBody = (<>
    <FormRow
      label="Available Qty"
      name={nameof<InventoryDTO>(p => p.availableQty)}
      inputType="number"
      value={formData.inventory?.availableQty}
      handleChange={handleChange}
    />
    <FormRow
      label="Shelf Life"
      name={nameof<InventoryDTO>(p => p.shelfLife)}
      inputType="number"
      value={formData.inventory?.shelfLife}
      handleChange={handleChange}
    />
    <FormRow
      label="Low Stock Alert"
      name={nameof<InventoryDTO>(p => p.minimumQtyAlert)}
      inputType="number"
      value={formData.inventory?.minimumQtyAlert}
      handleChange={handleChange}
    />
    {formData.inventory?.updateDate &&
      <div>
        Last updated on {formData.inventory?.updateDate}
      </div>}
  </>)

  const automaticBody = (
    <div>
      <FormRow
        label="Reload Size"
        name={nameof<ReloadConfigDTO>(p => p.reloadSize)}
        inputType="number"
        value={formData.reloadConfig.reloadSize}
        handleChange={handleChange}
      />
      <FormRow
        label="Low Stock Limit"
        name={nameof<ReloadConfigDTO>(p => p.lowStockLimit)}
        inputType="number"
        value={formData.reloadConfig.lowStockLimit}
        handleChange={handleChange}
      />
      <FormRow
        label="Max Available Qty"
        name={nameof<ReloadConfigDTO>(p => p.maxAvailabileQty)}
        inputType="number"
        value={formData.reloadConfig.maxAvailabileQty}
        handleChange={handleChange}
      />
      <DaysCheckboxes
        data={formData.reloadConfig}
        handleChange={handleChange}
      />
    </div>
  )

  const modalBody = (
    <div className={classes.form}>
      <Button style={getActiveStyle("manual")}
        onClick={e => setInventoryType("manual")}
      >
        Manual
      </Button>
      <Button style={getActiveStyle("automatic")}
        onClick={e => setInventoryType("automatic")}
      >
        Automatic
      </Button>
      {inventoryType === "manual"
        ? manualBody
        : automaticBody}
    </div>);

  const getTitle = () => openConfirmPopup ? "Warning!" : "Edit Menu";

  const closeOnDirtyDataConfirmBody = (
    <div>
      <div>There are unsaved modifications</div>
      <div>Are you sure you want to leave without saving?</div>
    </div>
  );

  const getBody = () => openConfirmPopup ? closeOnDirtyDataConfirmBody : modalBody;

  const modalButtons = (
    <div className={classes.form_footer_buttons}>
      <Button onClick={onModalClose}
        className={classes.form_footer_buttons_cancel}
      >Cancel</Button>
      <Button onClick={onSave}
        className={classes.form_footer_buttons_save}
        style={{ backgroundColor: businessInfo?.theme?.color }}
      >Save</Button>
    </div>
  );

  const confirmButtons = (
    <div className={classes.form_footer_buttons}>
      <Button onClick={onPopupClose}
        className={classes.form_footer_buttons_cancel}
      >No</Button>
      <Button onClick={onPopupOk}
        className={classes.form_footer_buttons_save}
        style={{ backgroundColor: businessInfo?.theme?.color }}
      >Yes</Button>
    </div>
  )

  const getButtons = () => openConfirmPopup ? confirmButtons : modalButtons;

  return (
    <GlobalModal
      open={showModal}
      handleClose={onModalClose}
      title={getTitle()}
      body={getBody()}
      buttons={getButtons()}
    />
  );
}

export default OptionInventoryForm;