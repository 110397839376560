import { DISCOUNT_VALUE_TYPE } from "./Types/Types";

export const baseUrl = process.env.REACT_APP_API_URL;
export const minPaperBagFee = 0.25;
export const bestSellerNumLastYears = 3;
export const imgUploadSizeLimit = 0.5; // in MB

export const discountValueTypes = [
  { name: "Amount", value: DISCOUNT_VALUE_TYPE.amount },
  { name: "Percentage", value: DISCOUNT_VALUE_TYPE.percentage },
  { name: "BOGO", value: DISCOUNT_VALUE_TYPE.bogo }
]

console.log("Using backend APIs at: " + process.env.REACT_APP_API_URL);