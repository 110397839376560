import classes from "./OverallPriceModifierEditForm.module.scss";
import FormVisibilityRules from '../FormVisibilityRules/FormVisibilityRules';
import GlobalModal from '../GlobalModal/GlobalModal';
import FormRow, { ChangeEventParams } from '../FormRow/FormRow';
import { nameof } from 'ts-simple-nameof';
import { useState, ChangeEvent, useEffect } from "react";
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Button } from '@material-ui/core';
import {
  BusinessInfo, DiscountRuleDTO, DiscountRuleFormData, DISCOUNT_TYPE, DISCOUNT_VALUE_TYPE, NameString
} from '../../Types/Types';
import {
  convertVisibilityDays, daysFields, formatMoney, normalizeMoney, nullReplacer, timeNumberToStr, timeStrToNumber
} from '../../common/pageUtils';

export enum OverallFormType {
  Discount,
  Charge
}

interface OverallPriceModifierEditFormProps {
  formType: OverallFormType;
  formTitle: string;
  typeOptions: NameString[];
  showModal: boolean;
  setModalOpen: Function;
  priceAdjustment?: DiscountRuleDTO;
  setBusinessInfo: Function;
  saveBusinessInfo: Function;
}


const OverallPriceModifierEditForm: React.FC<OverallPriceModifierEditFormProps> = (props) => {

  const {
    showModal, setModalOpen, setBusinessInfo, priceAdjustment = undefined, saveBusinessInfo
  } = props;

  const { formType, typeOptions, formTitle } = props;

  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [validationErrors, setValidationErrors] = useState("");
  const [prevTitle, setPrevTitle] = useState("");
  const [isEdit] = useState(priceAdjustment ? true : false);

  const [formData, setFormData] = useState<DiscountRuleFormData>({
    title: "",
    Sun: false, Mon: false,
    Tue: false, Wed: false,
    Thu: false, Fri: false, Sat: false,
    endDate: "",
    startDate: "",
    endTime: "",
    startTime: "",
    minPurchase: "",
    maxPurchase: "",
    type: DISCOUNT_VALUE_TYPE.amount,
    value: 0,
    discountType: "All",
  });

  const [originalData, setOriginalData] = useState({
    ...formData,
  });

  const isTypeCharge = formType === OverallFormType.Charge;

  useEffect(() => {
    if (!priceAdjustment) return;

    const daysObj = convertVisibilityDays(priceAdjustment.days);
    const { days, ...filtered } = priceAdjustment;

    const initialData: DiscountRuleFormData = {
      ...filtered,
      ...daysObj,
      endTime: timeNumberToStr(priceAdjustment.endTime),
      startTime: timeNumberToStr(priceAdjustment.startTime),
    };

    const isAmount = priceAdjustment.type === DISCOUNT_VALUE_TYPE.amount;
    let value = priceAdjustment.value;
    if (isTypeCharge && value < 0) {
      value = -value;
    }

    initialData.value = formatMoney(value, isAmount);
    initialData.maxPurchase = (isNaN(Number(priceAdjustment.maxPurchase)) ? 0 : Number(priceAdjustment.maxPurchase)) / 100;
    initialData.minPurchase = (isNaN(Number(priceAdjustment.minPurchase)) ? 0 : Number(priceAdjustment.minPurchase)) / 100;

    setFormData(initialData);
    setOriginalData({ ...initialData });
  }, [priceAdjustment, businessInfo?.serviceProviderUserId]);

  const onModalClose = (e) => {
    const originalJsonStr = JSON.stringify(originalData, nullReplacer);
    const dirtiedJsonStr = JSON.stringify(formData, nullReplacer);
    if (originalJsonStr !== dirtiedJsonStr) {
      setOpenConfirmPopup(true);
      return;
    }
    setModalOpen(false);
  }

  const getFormDtoSnapshot = (): DiscountRuleDTO => {

    const {
      Sun, Mon,
      Tue, Wed,
      Thu, Fri, Sat,
      ...otherFields
    } = formData;
    const isAmount = formData.type === DISCOUNT_VALUE_TYPE.amount;
    let newValue = normalizeMoney(formData.value, isAmount);

    if (isTypeCharge && newValue > 0) {
      newValue = -newValue;
    }

    const newData: DiscountRuleDTO = {
      ...otherFields,
      days: daysFields.filter(day => formData[day] === true),
      value: newValue,
      minPurchase: normalizeMoney(formData.minPurchase, true),
      maxPurchase: normalizeMoney(formData.maxPurchase, true),
      startTime: timeStrToNumber(formData.startTime),
      endTime: timeStrToNumber(formData.endTime),
    }

    return newData;
  }

  const updateBusinessInfo = (updateFunc: (prev: BusinessInfo) => BusinessInfo | void) => {
    if (!validateForm()) return;

    setBusinessInfo(updateFunc);

    setModalOpen(false);
    saveBusinessInfo();
  }

  const onSave = async (e) => {
    if (!priceAdjustment) return;
    const updatedData = getFormDtoSnapshot();
    updateBusinessInfo((prev: BusinessInfo) => {
      const newBusinessInfo = { ...prev };
      const overallDiscounts = newBusinessInfo.overallDiscounts;
      if (!overallDiscounts) return;
      const oldDscIndex = overallDiscounts.findIndex(dsc => dsc === priceAdjustment);
      if (oldDscIndex === -1) return;
      overallDiscounts[oldDscIndex] = updatedData;
      return newBusinessInfo;
    })
  }

  const onAdd = async (e) => {
    if (isEdit) return;

    const updatedData = getFormDtoSnapshot();
    updateBusinessInfo((prev: BusinessInfo) => {
      const newBusinessInfo = { ...prev };
      const overallDiscounts = newBusinessInfo.overallDiscounts;
      if (!overallDiscounts) return;
      overallDiscounts.push(updatedData);
      return newBusinessInfo;
    })

  }

  const onPopupOk = async (event) => {
    setOpenConfirmPopup(false);
    setModalOpen(false);
  }

  const onPopupClose = (event) => {
    setModalOpen(true);
    setOpenConfirmPopup(false);
  }

  const validateForm = () => {
    // if (!formData.minPurchase || formData.minPurchase === 0) {
    //   setValidationErrors("A min. purchase limit must be provided!");
    //   return false;
    // }

    setValidationErrors("");
    return true;
  }

  const handleChange = (event: ChangeEventParams) => {
    const target = event.target;
    const nameProp = target.name;
    const checkedInputs = ["checkbox", "radio"]
    const value = checkedInputs.includes(target.type) ? target.checked : target.value;

    if (formData.hasOwnProperty(nameProp)) {

      const newFormData = {
        ...formData,
        [nameProp]: value
      };

      const isTypeChange = nameProp === nameof<DiscountRuleFormData>(p => p.type);
      if (isTypeChange) {
        const isAmount = value === DISCOUNT_VALUE_TYPE.amount;
        const extracted = formatMoney(0, isAmount);
        newFormData.value = extracted;
      }

      setFormData(newFormData);
      return newFormData;
    }

    console.warn(`No prop on discountRule or reloadConfig called ${nameProp}!`);
  }


  const isPromoCodeType = formData?.discountType === DISCOUNT_TYPE.promoCode;
  const handlePromoCodeName = (e: ChangeEventParams) => {
    const target = e.target;
    const value = target.value;
    if (isPromoCodeType && value?.toString()?.includes(' ')) return;

    handleChange(e);
  }

  const handleTypeChange = (e: ChangeEventParams) => {
    const target = e.target;
    const value = target.value;

    const changedFormData = handleChange(e);
    const newFormData = changedFormData || formData;
    if (value === DISCOUNT_TYPE.promoCode && formData.title) {
      setPrevTitle(formData?.title ?? "");
      setFormData({
        ...newFormData,
        title: ""
      });
    }

    if (value !== DISCOUNT_TYPE.promoCode && prevTitle) {
      setFormData({
        ...newFormData,
        title: prevTitle
      });
    }
  }


  const preFix = formData.type === DISCOUNT_VALUE_TYPE.amount ? "$" : "";
  const postFix = formData.type === DISCOUNT_VALUE_TYPE.percentage ? "%" : "";
  const modalBody = (
    <div className={classes.form}>

      <div className={classes.error}>
        {validationErrors}
      </div>

      <FormRow
        label={isPromoCodeType ? "Promo Code" : "Title"}
        name={nameof<DiscountRuleFormData>(p => p.title)}
        inputType="text"
        value={formData?.title}
        handleChange={handlePromoCodeName}
      />
      <FormRow<NameString>
        label="Type"
        name={nameof<DiscountRuleFormData>(p => p.discountType)}
        inputType="dropdown"
        value={formData?.discountType ?? ""}
        handleChange={handleTypeChange}
        options={typeOptions}
        valueField={val => val.value}
        displayField={val => val.name}
      />
      <FormRow<NameString>
        label="Value Type"
        name={nameof<DiscountRuleFormData>(p => p.type)}
        inputType="dropdown"
        value={formData?.type}
        handleChange={handleChange}
        options={[
          { name: "", value: "" },
          { name: "Amount", value: DISCOUNT_VALUE_TYPE.amount },
          { name: "Percentage", value: DISCOUNT_VALUE_TYPE.percentage }
        ]}
        valueField={val => val.value}
        displayField={val => val.name}
      />
      <FormRow
        label="Value"
        name={nameof<DiscountRuleFormData>(p => p.value)}
        inputType="number"
        value={formData?.value}
        handleChange={handleChange}
        preFix={preFix}
        postFix={postFix}
        placeholder="0.00"
      />
      <FormRow
        label="Min Purchase"
        name={nameof<DiscountRuleFormData>(p => p.minPurchase)}
        inputType="price"
        value={formData?.minPurchase}
        handleChange={handleChange}
        placeholder="0.00"
      />
      <FormRow
        label="Max Purchase"
        name={nameof<DiscountRuleFormData>(p => p.maxPurchase)}
        inputType="price"
        max={999999}
        value={formData?.maxPurchase}
        handleChange={handleChange}
        placeholder="0.00"
      />
      <FormRow
        label="Redeem Limit"
        name={nameof<DiscountRuleFormData>(p => p.redeemTimesLimit)}
        inputType="number"
        value={formData?.redeemTimesLimit}
        handleChange={handleChange}
      />
      <FormVisibilityRules
        handleChange={handleChange}
        visibilityRules={formData}
      />
    </div>);


  const closeOnDirtyDataConfirmBody = (
    <div>
      <div>There are unsaved modifications</div>
      <div>Are you sure you want to leave without saving?</div>
    </div>
  );

  const modalButtons = (
    <div className={classes.form_footer_buttons}>
      <Button onClick={onModalClose}
        className={classes.form_footer_buttons_cancel}
      >Cancel</Button>
      {isEdit
        ? <Button onClick={onSave}
          className={classes.form_footer_buttons_save}
          style={{ backgroundColor: businessInfo?.theme?.color }}
        >Save</Button>
        : <Button onClick={onAdd}
          className={classes.form_footer_buttons_add}
          style={{ backgroundColor: businessInfo?.theme?.color }}
        >Add</Button>}
    </div>
  );

  const confirmButtons = (
    <div className={classes.form_footer_buttons}>
      <Button onClick={onPopupClose}
        className={classes.form_footer_buttons_cancel}
      >No</Button>
      <Button onClick={onPopupOk}
        className={classes.form_footer_buttons_save}
        style={{ backgroundColor: businessInfo?.theme?.color }}
      >Yes</Button>
    </div>
  )

  return (
    <GlobalModal
      open={showModal}
      handleClose={onModalClose}
      title={openConfirmPopup ? "Warning!" : `${isEdit ? "Edit" : "Add"} ${formTitle}`}
      body={openConfirmPopup ? closeOnDirtyDataConfirmBody : modalBody}
      buttons={openConfirmPopup ? confirmButtons : modalButtons}
    />
  );
}

export default OverallPriceModifierEditForm;