import { IMaskInput, IMaskInputProps } from 'react-imask';
import React from "react";
import { InputBaseComponentProps } from '@material-ui/core';

type CustomProps = {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

type NumberMaskInputProps = CustomProps & IMaskInputProps<HTMLInputElement> & InputBaseComponentProps;

export const NumberMaskInput = React.forwardRef<HTMLInputElement, NumberMaskInputProps>(
    (props, ref) => {
        const { onChange, name } = props as CustomProps;
        const { ...others } = props as IMaskInputProps<HTMLInputElement>; // defaults
        return (
            <IMaskInput
                {...others}
                ref={ref}
                onAccept={(value: any, mask) => onChange({
                    target: {
                        name: name,
                        value: mask.unmaskedValue
                    }
                })}
                overwrite />
        );
    }
);
